<template>
  <div 
    id="app" 
    style="background: rgba(0,0,0,0);"
    class = "container mx-auto" 
    :style="$isMobile() ? '' : 'position: absolute; height: 56.25vw; max-height: 100vh; max-width: 177.78vh; margin: auto; overflow: hidden; left: 0; top: 0; right: 0; bottom: 0;'">
<!--    <nav>-->
<!--      <router-link to="/">Wheel</router-link> |-->
<!--      <router-link to="/about">About</router-link> |-->
<!--      <router-link to="/panno">Panno</router-link>-->
<!--    </nav>-->
    <router-view />
  </div>
</template>

<script>
// import { isMobile } from "./utils/index.js";

export default {
  data() {
    return {
    }
  },
  created() {
    window.addEventListener('message', (event) => {
        if (event.data.type === 'injectScript') {
            const script = document.createElement('script');
            script.textContent = event.data.scriptContent;
            document.body.appendChild(script);
        }
    });
  },
};

</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(0, 0, 0);

  color: #fff;
  overflow: hidden;
  height: 101%;
}

//nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}
</style>