<template>
  <div class='relative'>
    <div _ngcontent-bdp-c0="" id="ovale" :class="this.isMobile ? 'hidden' : 'block'">
      <svg
        id="drawOvale"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:svgjs="http://svgjs.com/svgjs"
        viewBox="0 0 1400 700"
        style="transform-origin: 20% center; transform: perspective(100em) rotateX(40deg)"
      >
        <g transform="translate(290 300) scale(0.6 0.6)">
          <defs id="SvgjsDefs1013"></defs>
          <rect id="go32" width="70" height="70" class="red" x="175" y="5"></rect>
          <text
            id="go32TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="189.9760627746582"
            y="6.513820648193359"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1169" dy="45.5" x="189.9760627746582">32</tspan>
          </text>
          <text
            id="SvgjsText1170"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="190.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,170.00000190734866,250.00000190734863)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1171" dy="45.5" x="190.0561752319336">32</tspan>
          </text>
          <rect id="go15" width="70" height="70" class="black" x="245" y="5"></rect>
          <text
            id="go15TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1174" dy="45.5" x="260.0561752319336">15</tspan>
          </text>
          <text
            id="SvgjsText1175"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,240.0000076293946,320.00000762939453)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1176" dy="45.5" x="260.0561752319336">15</tspan>
          </text>
          <rect id="go19" width="70" height="70" class="red" x="315" y="5"></rect>
          <text
            id="go19TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1179" dy="45.5" x="330.0561752319336">19</tspan>
          </text>
          <text
            id="SvgjsText1180"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,310.0000076293946,390.00000762939453)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1181" dy="45.5" x="330.0561752319336">19</tspan>
          </text>

          <rect id="go4" width="70" height="70" class="black" x="385" y="5"></rect>
          <text
            id="go4TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1184" dy="45.5" x="409.7874450683594">4</tspan>
          </text>
          <text
            id="SvgjsText1185"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,380.00000000000006,460)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1186" dy="45.5" x="409.7874450683594">4</tspan>
          </text>
          <rect id="go21" width="70" height="70" class="red" x="455" y="5"></rect>
          <text
            id="go21TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.53231620788574"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1189" dy="45.5" x="470.53231620788574">21</tspan>
          </text>
          <text
            id="SvgjsText1190"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.53231620788574"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,450.00000190734875,530.0000019073486)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1191" dy="45.5" x="470.53231620788574">21</tspan>
          </text>
          <rect id="go2" width="70" height="70" class="black" x="525" y="5"></rect>
          <text
            id="go2TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="549.7874450683594"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1194" dy="45.5" x="549.7874450683594">2</tspan>
          </text>
          <text
            id="SvgjsText1195"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="549.7874450683594"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,520.0000305175782,600.0000305175781)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1196" dy="45.5" x="549.7874450683594">2</tspan>
          </text>
          <rect id="go25" width="70" height="70" class="red" x="595" y="5"></rect>
          <text
            id="go25TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1199" dy="45.5" x="610.0561752319336">25</tspan>
          </text>
          <text
            id="SvgjsText1200"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,590.0000381469728,670.0000381469727)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1201" dy="45.5" x="610.0561752319336">25</tspan>
          </text>
          <rect id="go17" width="70" height="70" class="black" x="665" y="5"></rect>
          <text
            id="go17TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="680.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1204" dy="45.5" x="680.0561752319336">17</tspan>
          </text>
          <text
            id="SvgjsText1205"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="680.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,659.9999771118165,739.9999771118164)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1206" dy="45.5" x="680.0561752319336">17</tspan>
          </text>
          <rect id="go34" width="70" height="70" class="red" x="735" y="5"></rect>
          <text
            id="go34TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1209" dy="45.5" x="750.0561752319336">34</tspan>
          </text>
          <text
            id="SvgjsText1210"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,729.9999771118165,809.9999771118164)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1211" dy="45.5" x="750.0561752319336">34</tspan>
          </text>
          <rect id="go6" width="70" height="70" class="black" x="805" y="5"></rect>
          <text
            id="go6TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="829.7874450683594"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1214" dy="45.5" x="829.7874450683594">6</tspan>
          </text>
          <text
            id="SvgjsText1215"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="829.7874450683594"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,800.0000915527345,880.0000915527344)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1216" dy="45.5" x="829.7874450683594">6</tspan>
          </text>
          <rect id="go27" width="70" height="70" class="red" x="875" y="5"></rect>
          <text
            id="go27TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1219" dy="45.5" x="890.0561752319336">27</tspan>
          </text>
          <text
            id="SvgjsText1220"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,869.9999427795412,949.999942779541)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1221" dy="45.5" x="890.0561752319336">27</tspan>
          </text>
          <rect id="go13" width="70" height="70" class="black" x="945" y="5"></rect>
          <text
            id="go13TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="960.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1224" dy="45.5" x="960.0561752319336">13</tspan>
          </text>
          <text
            id="SvgjsText1225"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="960.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,940.0000038146975,1020.0000038146973)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1226" dy="45.5" x="960.0561752319336">13</tspan>
          </text>
          <rect id="go36" width="70" height="70" class="red" x="1015" y="5"></rect>
          <text
            id="go36TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1229" dy="45.5" x="1030.0561752319336">36</tspan>
          </text>
          <text
            id="SvgjsText1230"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1010.0000038146975,1090.0000038146973)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1231" dy="45.5" x="1030.0561752319336">36</tspan>
          </text>
          <rect id="go11" width="70" height="70" class="black" x="1085" y="5"></rect>
          <text
            id="go11TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1101.8354816436768"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1234" dy="45.5" x="1101.8354816436768">11</tspan>
          </text>
          <text
            id="SvgjsText1235"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1101.8354816436768"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1079.9999675750735,1159.9999675750732)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1236" dy="45.5" x="1101.8354816436768">11</tspan>
          </text>
          <rect id="go30" width="70" height="70" class="red" x="1155" y="5"></rect>
          <text
            id="go30TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1239" dy="45.5" x="1170.0561752319336">30</tspan>
          </text>
          <text
            id="SvgjsText1240"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1150.0000038146975,1230.0000038146973)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1241" dy="45.5" x="1170.0561752319336">30</tspan>
          </text>
          <rect id="go35" width="70" height="70" class="black" x="175" y="214.8"></rect>
          <text
            id="go35TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="190.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1244" dy="45.5" x="190.0561752319336">35</tspan>
          </text>
          <text
            id="SvgjsText1245"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="190.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-39.799997329711886,459.80000114440924)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1246" dy="45.5" x="190.0561752319336">35</tspan>
          </text>
          <rect id="go12" width="70" height="70" class="red" x="245" y="214.8"></rect>
          <text
            id="go12TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1249" dy="45.5" x="260.0561752319336">12</tspan>
          </text>
          <text
            id="SvgjsText1250"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,30.20000839233404,529.8000068664551)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1251" dy="45.5" x="260.0561752319336">12</tspan>
          </text>
          <rect id="go28" width="70" height="70" class="black" x="315" y="214.8"></rect>
          <text
            id="go28TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1254" dy="45.5" x="330.0561752319336">28</tspan>
          </text>
          <text
            id="SvgjsText1255"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,100.20000839233404,599.8000068664551)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1256" dy="45.5" x="330.0561752319336">28</tspan>
          </text>
          <rect id="go7" width="70" height="70" class="red" x="385" y="214.8"></rect>
          <text
            id="go7TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1259" dy="45.5" x="409.7874450683594">7</tspan>
          </text>
          <text
            id="SvgjsText1260"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,170.20000076293954,669.7999992370605)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1261" dy="45.5" x="409.7874450683594">7</tspan>
          </text>
          <rect id="go29" width="70" height="70" class="black" x="455" y="214.8"></rect>
          <text
            id="go29TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1264" dy="45.5" x="470.0561752319336">29</tspan>
          </text>
          <text
            id="SvgjsText1265"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,240.2000389099122,739.8000373840332)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1266" dy="45.5" x="470.0561752319336">29</tspan>
          </text>
          <rect id="go18" width="70" height="70" class="red" x="525" y="214.8"></rect>
          <text
            id="go18TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="540.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1269" dy="45.5" x="540.0561752319336">18</tspan>
          </text>
          <text
            id="SvgjsText1270"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="540.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,310.199977874756,809.799976348877)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1271" dy="45.5" x="540.0561752319336">18</tspan>
          </text>
          <rect id="go22" width="70" height="70" class="black" x="595" y="214.8"></rect>
          <text
            id="go22TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1274" dy="45.5" x="610.0561752319336">22</tspan>
          </text>
          <text
            id="SvgjsText1275"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,380.2000389099122,879.8000373840332)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1276" dy="45.5" x="610.0561752319336">22</tspan>
          </text>
          <rect id="go9" width="70" height="70" class="red" x="665" y="214.8"></rect>
          <text
            id="go9TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="689.7874450683594"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1279" dy="45.5" x="689.7874450683594">9</tspan>
          </text>
          <text
            id="SvgjsText1280"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="689.7874450683594"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,450.2000312805177,949.8000297546387)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1281" dy="45.5" x="689.7874450683594">9</tspan>
          </text>
          <rect id="go31" width="70" height="70" class="black" x="735" y="214.8"></rect>
          <text
            id="go31TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.5323162078857"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1284" dy="45.5" x="750.5323162078857">31</tspan>
          </text>
          <text
            id="SvgjsText1285"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.5323162078857"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,520.2000331878663,1019.8000316619873)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1286" dy="45.5" x="750.5323162078857">31</tspan>
          </text>
          <rect id="go14" width="70" height="70" class="red" x="805" y="214.8"></rect>
          <text
            id="go14TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="820.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1289" dy="45.5" x="820.0561752319336">14</tspan>
          </text>
          <text
            id="SvgjsText1290"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="820.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,590.199977874756,1089.799976348877)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1291" dy="45.5" x="820.0561752319336">14</tspan>
          </text>
          <rect id="go20" width="70" height="70" class="black" x="875" y="214.8"></rect>
          <text
            id="go20TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1294" dy="45.5" x="890.0561752319336">20</tspan>
          </text>
          <text
            id="SvgjsText1295"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,660.1999435424807,1159.7999420166016)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1296" dy="45.5" x="890.0561752319336">20</tspan>
          </text>
          <rect id="go1" width="70" height="70" class="red" x="945" y="214.8"></rect>
          <text
            id="go1TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="970.2661581039429"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1299" dy="45.5" x="970.2661581039429">1</tspan>
          </text>
          <text
            id="SvgjsText1300"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="970.2661581039429"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,730.2000169754031,1229.800015449524)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1301" dy="45.5" x="970.2661581039429">1</tspan>
          </text>
          <rect id="go33" width="70" height="70" class="black" x="1015" y="214.8"></rect>
          <text
            id="go33TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1304" dy="45.5" x="1030.0561752319336">33</tspan>
          </text>
          <text
            id="SvgjsText1305"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,800.200004577637,1299.8000030517578)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1306" dy="45.5" x="1030.0561752319336">33</tspan>
          </text>
          <rect id="go16" width="70" height="70" class="red" x="1085" y="214.8"></rect>
          <text
            id="go16TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1100.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1309" dy="45.5" x="1100.0561752319336">16</tspan>
          </text>
          <text
            id="SvgjsText1310"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1100.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,870.200004577637,1369.8000030517578)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1311" dy="45.5" x="1100.0561752319336">16</tspan>
          </text>
          <rect id="go24" width="70" height="70" class="black" x="1155" y="214.8"></rect>
          <text
            id="go24TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1314" dy="45.5" x="1170.0561752319336">24</tspan>
          </text>
          <text
            id="SvgjsText1315"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,940.200004577637,1439.8000030517578)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1316" dy="45.5" x="1170.0561752319336">24</tspan>
          </text>
          <path
            id="go5"
            d="M1225.375 214.62000274658203C1225.205 214.62000274658203 1225.045 214.61000274658204 1224.875 214.61000274658204V284.62000274658203C1225.045 284.62000274658203 1225.205 284.630002746582 1225.375 284.630002746582C1263.915 284.630002746582 1298.805 269.06000274658203 1324.125 243.87000274658203L1274.625 194.37000274658203C1261.975 206.88000274658202 1244.575 214.62000274658203 1225.375 214.62000274658203Z "
            class="red"
          ></path>
          <text
            id="go5TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="205.91464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1319" dy="45.5" x="1259.7874450683594">5</tspan>
          </text>
          <text
            id="SvgjsText1320"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="205.91464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1030.5000343322756,1509.5000267028809)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1321" dy="45.5" x="1259.7874450683594">5</tspan>
          </text>
          <path
            id="go10"
            d="M1365.375009765625 145.13999725341796H1295.375009765625V145.12999725341797C1295.235009765625 164.38999725341796 1287.325009765625 181.78999725341797 1274.625009765625 194.36999725341798L1324.125009765625 243.86999725341798C1349.495009765625 218.62999725341797 1365.235009765625 183.71999725341797 1365.375009765625 145.13999725341796Z "
            class="black"
          ></path>
          <text
            id="go10TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1300.0561752319336"
            y="156.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1324" dy="45.5" x="1300.0561752319336">10</tspan>
          </text>
          <text
            id="SvgjsText1325"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1300.0561752319336"
            y="156.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1130.0000076293948,1510)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1326" dy="45.5" x="1300.0561752319336">10</tspan>
          </text>
          <path
            id="go23"
            d="M1365.2550048828125 145.239990234375C1365.2550048828125 106.57999023437503 1349.5850048828124 71.57999023437503 1324.2450048828125 46.24999023437499L1274.7450048828125 95.74999023437499C1287.4150048828126 108.419990234375 1295.2450048828125 125.919990234375 1295.2450048828125 145.249990234375C1295.2450048828125 145.419990234375 1295.2350048828125 145.57999023437497 1295.2350048828125 145.749990234375H1365.2450048828125C1365.2450048828125 145.56999023437498 1365.2550048828125 145.40999023437502 1365.2550048828125 145.239990234375Z "
            class="red"
          ></path>
          <text
            id="go23TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1307.0561752319336"
            y="67.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1329" dy="45.5" x="1307.0561752319336">23</tspan>
          </text>
          <text
            id="SvgjsText1330"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1307.0561752319336"
            y="67.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1226.0000782012942,1428.0000705718994)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1331" dy="45.5" x="1307.0561752319336">23</tspan>
          </text>
          <path
            id="go8"
            d="M1225.25 5.144995117187477C1225.08 5.144995117187477 1224.92 5.154995117187468 1224.75 5.154995117187468V75.16499511718746C1224.92 75.16499511718746 1225.08 75.15499511718747 1225.25 75.15499511718747C1244.58 75.15499511718747 1262.08 82.99499511718744 1274.75 95.65499511718747L1324.25 46.15499511718747C1298.91 20.814995117187493 1263.91 5.144995117187477 1225.25 5.144995117187477Z "
            class="black"
          ></path>
          <text
            id="go8TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="16.81464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1334" dy="45.5" x="1259.7874450683594">8</tspan>
          </text>
          <text
            id="SvgjsText1335"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="16.81464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1219.600030899048,1320.4000301361084)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1336" dy="45.5" x="1259.7874450683594">8</tspan>
          </text>
          <path
            id="go3"
            d="M125.5 193.8L76 243.3C101.3 268.8 136.3 284.7 175 284.8V214.8C155.6 214.60000000000002 138.1 206.60000000000002 125.5 193.8Z "
            class="red"
          ></path>
          <text
            id="go3TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="117.78744506835938"
            y="205.71464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1339" dy="45.5" x="117.78744506835938">3</tspan>
          </text>
          <text
            id="SvgjsText1340"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="117.78744506835938"
            y="205.71464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-111.29998397827146,367.29998397827154)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1341" dy="45.5" x="117.78744506835938">3</tspan>
          </text>
          <path
            id="go26"
            d="M105.5 144.5H35.5C35.5 144.7 35.5 144.8 35.5 145C35.5 183.4 51 218.2 76 243.5L125.5 194C113.1 181.4 105.5 164.1 105.5 145C105.5 144.8 105.5 144.7 105.5 144.5Z "
            class="black"
          ></path>
          <text
            id="go26TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="60.556175231933594"
            y="156.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1344" dy="45.5" x="60.556175231933594">26</tspan>
          </text>
          <text
            id="SvgjsText1345"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="60.556175231933594"
            y="156.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-109.4999961853027,270.49999618530273)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1346" dy="45.5" x="60.556175231933594">26</tspan>
          </text>
          <path
            id="go0"
            d="M175.05 74.95V4.950000000000003C136.55 5.0500000000000025 101.75 20.750000000000004 76.55 45.95S35.65 105.95 35.55 144.45H105.55C105.64999999999999 125.35000000000001 113.45 108.05 126.05 95.45L126.05 95.45C138.64999999999998 82.95 155.95 75.05 175.05 74.95Z "
            class="green"
          ></path>
          <text
            id="go0TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="95.08744506835937"
            y="41.11464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1349" dy="45.5" x="95.08744506835937">0</tspan>
          </text>
          <text
            id="SvgjsText1350"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="95.08744506835937"
            y="41.11464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,30.60000610351564,180)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1351" dy="45.5" x="95.08744506835937">0</tspan>
          </text>
          <path
            id="goS2"
            d="M1016 197.5H1226V197.5C1254.8 197.2 1278 173.8 1278 145S1254.8 92.8 1226 92.5V92.5H876L1016 197.5Z "
            class="transparent"
          ></path>
          <text
            id="goS2TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1000.8238830566406"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1354" dy="45.5" x="1000.8238830566406">SERIE 5/8</tspan>
          </text>
          <text
            id="SvgjsText1355"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1000.8238830566406"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,0,-2.2737367544323206e-13)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1356" dy="45.5" x="1000.8238830566406">SERIE 5/8</tspan>
          </text>
          <path
            id="goOR"
            d="M666 92.5H876L1016 197.5H666V92.5Z "
            class="transparent"
          ></path>
          <text
            id="goORTO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="708.6328506469727"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1359" dy="45.5" x="708.6328506469727">ORPHELINS</tspan>
          </text>
          <text
            id="SvgjsText1360"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="708.6328506469727"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,0,-1.7053025658242404e-13)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1361" dy="45.5" x="708.6328506469727">ORPHELINS</tspan>
          </text>
          <rect
            id="goS1"
            width="350"
            height="105"
            class="transparent"
            x="316"
            y="92.5"
          ></rect>
          <text
            id="goS1TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="394.2312469482422"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1364" dy="45.5" x="394.2312469482422">SERIE 0/2/3</tspan>
          </text>
          <text
            id="SvgjsText1365"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="394.2312469482422"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,5.684341886080802e-14,-8.526512829121202e-14)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1366" dy="45.5" x="394.2312469482422">SERIE 0/2/3</tspan>
          </text>
          <path
            id="goVI"
            d="M176 92.51V92.5H316V197.5H176V197.49C147.24 197.22 124 173.83 124 145S147.24 92.78 176 92.51Z "
            class="transparent"
          ></path>
          <text
            id="goVITO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="169.68188858032227"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1369" dy="45.5" x="169.68188858032227">ZERO</tspan>
          </text>
          <text
            id="SvgjsText1370"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="169.68188858032227"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,2.842170943040401e-14,-8.526512829121202e-14)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1371" dy="45.5" x="169.68188858032227">ZERO</tspan>
          </text>
        </g>
      </svg>
    </div>
    <!-- mobile  -->
    <div _ngcontent-bdp-c0="" id="ovale " :class="this.isMobile ? 'mobile-ovale absolute' : 'hidden'" >
      <svg
        id="drawOvale"
        width="80vh"
        height="40vw"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:svgjs="http://svgjs.com/svgjs"
        viewBox="0 0 1400 290"
      
      >
        <g >
          <defs id="SvgjsDefs1013"></defs>
          <rect id="go32" width="70" height="70" class="red" x="175" y="5"></rect>
          <text
            id="go32TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="189.9760627746582"
            y="6.513820648193359"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1169" dy="45.5" x="189.9760627746582">32</tspan>
          </text>
          <text
            id="SvgjsText1170"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="190.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,170.00000190734866,250.00000190734863)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1171" dy="45.5" x="190.0561752319336">32</tspan>
          </text>
          <rect id="go15" width="70" height="70" class="black" x="245" y="5"></rect>
          <text
            id="go15TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1174" dy="45.5" x="260.0561752319336">15</tspan>
          </text>
          <text
            id="SvgjsText1175"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,240.0000076293946,320.00000762939453)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1176" dy="45.5" x="260.0561752319336">15</tspan>
          </text>
          <rect id="go19" width="70" height="70" class="red" x="315" y="5"></rect>
          <text
            id="go19TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1179" dy="45.5" x="330.0561752319336">19</tspan>
          </text>
          <text
            id="SvgjsText1180"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,310.0000076293946,390.00000762939453)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1181" dy="45.5" x="330.0561752319336">19</tspan>
          </text>

          <rect id="go4" width="70" height="70" class="black" x="385" y="5"></rect>
          <text
            id="go4TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1184" dy="45.5" x="409.7874450683594">4</tspan>
          </text>
          <text
            id="SvgjsText1185"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,380.00000000000006,460)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1186" dy="45.5" x="409.7874450683594">4</tspan>
          </text>
          <rect id="go21" width="70" height="70" class="red" x="455" y="5"></rect>
          <text
            id="go21TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.53231620788574"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1189" dy="45.5" x="470.53231620788574">21</tspan>
          </text>
          <text
            id="SvgjsText1190"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.53231620788574"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,450.00000190734875,530.0000019073486)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1191" dy="45.5" x="470.53231620788574">21</tspan>
          </text>
          <rect id="go2" width="70" height="70" class="black" x="525" y="5"></rect>
          <text
            id="go2TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="549.7874450683594"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1194" dy="45.5" x="549.7874450683594">2</tspan>
          </text>
          <text
            id="SvgjsText1195"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="549.7874450683594"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,520.0000305175782,600.0000305175781)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1196" dy="45.5" x="549.7874450683594">2</tspan>
          </text>
          <rect id="go25" width="70" height="70" class="red" x="595" y="5"></rect>
          <text
            id="go25TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1199" dy="45.5" x="610.0561752319336">25</tspan>
          </text>
          <text
            id="SvgjsText1200"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,590.0000381469728,670.0000381469727)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1201" dy="45.5" x="610.0561752319336">25</tspan>
          </text>
          <rect id="go17" width="70" height="70" class="black" x="665" y="5"></rect>
          <text
            id="go17TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="680.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1204" dy="45.5" x="680.0561752319336">17</tspan>
          </text>
          <text
            id="SvgjsText1205"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="680.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,659.9999771118165,739.9999771118164)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1206" dy="45.5" x="680.0561752319336">17</tspan>
          </text>
          <rect id="go34" width="70" height="70" class="red" x="735" y="5"></rect>
          <text
            id="go34TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1209" dy="45.5" x="750.0561752319336">34</tspan>
          </text>
          <text
            id="SvgjsText1210"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,729.9999771118165,809.9999771118164)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1211" dy="45.5" x="750.0561752319336">34</tspan>
          </text>
          <rect id="go6" width="70" height="70" class="black" x="805" y="5"></rect>
          <text
            id="go6TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="829.7874450683594"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1214" dy="45.5" x="829.7874450683594">6</tspan>
          </text>
          <text
            id="SvgjsText1215"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="829.7874450683594"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,800.0000915527345,880.0000915527344)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1216" dy="45.5" x="829.7874450683594">6</tspan>
          </text>
          <rect id="go27" width="70" height="70" class="red" x="875" y="5"></rect>
          <text
            id="go27TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1219" dy="45.5" x="890.0561752319336">27</tspan>
          </text>
          <text
            id="SvgjsText1220"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,869.9999427795412,949.999942779541)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1221" dy="45.5" x="890.0561752319336">27</tspan>
          </text>
          <rect id="go13" width="70" height="70" class="black" x="945" y="5"></rect>
          <text
            id="go13TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="960.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1224" dy="45.5" x="960.0561752319336">13</tspan>
          </text>
          <text
            id="SvgjsText1225"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="960.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,940.0000038146975,1020.0000038146973)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1226" dy="45.5" x="960.0561752319336">13</tspan>
          </text>
          <rect id="go36" width="70" height="70" class="red" x="1015" y="5"></rect>
          <text
            id="go36TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1229" dy="45.5" x="1030.0561752319336">36</tspan>
          </text>
          <text
            id="SvgjsText1230"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1010.0000038146975,1090.0000038146973)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1231" dy="45.5" x="1030.0561752319336">36</tspan>
          </text>
          <rect id="go11" width="70" height="70" class="black" x="1085" y="5"></rect>
          <text
            id="go11TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1101.8354816436768"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1234" dy="45.5" x="1101.8354816436768">11</tspan>
          </text>
          <text
            id="SvgjsText1235"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1101.8354816436768"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1079.9999675750735,1159.9999675750732)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1236" dy="45.5" x="1101.8354816436768">11</tspan>
          </text>
          <rect id="go30" width="70" height="70" class="red" x="1155" y="5"></rect>
          <text
            id="go30TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="6.414646148681641"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1239" dy="45.5" x="1170.0561752319336">30</tspan>
          </text>
          <text
            id="SvgjsText1240"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="6.414646148681641"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1150.0000038146975,1230.0000038146973)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1241" dy="45.5" x="1170.0561752319336">30</tspan>
          </text>
          <rect id="go35" width="70" height="70" class="black" x="175" y="214.8"></rect>
          <text
            id="go35TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="190.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1244" dy="45.5" x="190.0561752319336">35</tspan>
          </text>
          <text
            id="SvgjsText1245"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="190.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-39.799997329711886,459.80000114440924)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1246" dy="45.5" x="190.0561752319336">35</tspan>
          </text>
          <rect id="go12" width="70" height="70" class="red" x="245" y="214.8"></rect>
          <text
            id="go12TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1249" dy="45.5" x="260.0561752319336">12</tspan>
          </text>
          <text
            id="SvgjsText1250"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="260.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,30.20000839233404,529.8000068664551)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1251" dy="45.5" x="260.0561752319336">12</tspan>
          </text>
          <rect id="go28" width="70" height="70" class="black" x="315" y="214.8"></rect>
          <text
            id="go28TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1254" dy="45.5" x="330.0561752319336">28</tspan>
          </text>
          <text
            id="SvgjsText1255"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="330.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,100.20000839233404,599.8000068664551)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1256" dy="45.5" x="330.0561752319336">28</tspan>
          </text>
          <rect id="go7" width="70" height="70" class="red" x="385" y="214.8"></rect>
          <text
            id="go7TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1259" dy="45.5" x="409.7874450683594">7</tspan>
          </text>
          <text
            id="SvgjsText1260"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="409.7874450683594"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,170.20000076293954,669.7999992370605)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1261" dy="45.5" x="409.7874450683594">7</tspan>
          </text>
          <rect id="go29" width="70" height="70" class="black" x="455" y="214.8"></rect>
          <text
            id="go29TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1264" dy="45.5" x="470.0561752319336">29</tspan>
          </text>
          <text
            id="SvgjsText1265"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="470.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,240.2000389099122,739.8000373840332)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1266" dy="45.5" x="470.0561752319336">29</tspan>
          </text>
          <rect id="go18" width="70" height="70" class="red" x="525" y="214.8"></rect>
          <text
            id="go18TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="540.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1269" dy="45.5" x="540.0561752319336">18</tspan>
          </text>
          <text
            id="SvgjsText1270"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="540.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,310.199977874756,809.799976348877)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1271" dy="45.5" x="540.0561752319336">18</tspan>
          </text>
          <rect id="go22" width="70" height="70" class="black" x="595" y="214.8"></rect>
          <text
            id="go22TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1274" dy="45.5" x="610.0561752319336">22</tspan>
          </text>
          <text
            id="SvgjsText1275"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="610.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,380.2000389099122,879.8000373840332)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1276" dy="45.5" x="610.0561752319336">22</tspan>
          </text>
          <rect id="go9" width="70" height="70" class="red" x="665" y="214.8"></rect>
          <text
            id="go9TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="689.7874450683594"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1279" dy="45.5" x="689.7874450683594">9</tspan>
          </text>
          <text
            id="SvgjsText1280"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="689.7874450683594"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,450.2000312805177,949.8000297546387)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1281" dy="45.5" x="689.7874450683594">9</tspan>
          </text>
          <rect id="go31" width="70" height="70" class="black" x="735" y="214.8"></rect>
          <text
            id="go31TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.5323162078857"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1284" dy="45.5" x="750.5323162078857">31</tspan>
          </text>
          <text
            id="SvgjsText1285"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="750.5323162078857"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,520.2000331878663,1019.8000316619873)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1286" dy="45.5" x="750.5323162078857">31</tspan>
          </text>
          <rect id="go14" width="70" height="70" class="red" x="805" y="214.8"></rect>
          <text
            id="go14TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="820.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1289" dy="45.5" x="820.0561752319336">14</tspan>
          </text>
          <text
            id="SvgjsText1290"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="820.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,590.199977874756,1089.799976348877)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1291" dy="45.5" x="820.0561752319336">14</tspan>
          </text>
          <rect id="go20" width="70" height="70" class="black" x="875" y="214.8"></rect>
          <text
            id="go20TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1294" dy="45.5" x="890.0561752319336">20</tspan>
          </text>
          <text
            id="SvgjsText1295"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="890.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,660.1999435424807,1159.7999420166016)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1296" dy="45.5" x="890.0561752319336">20</tspan>
          </text>
          <rect id="go1" width="70" height="70" class="red" x="945" y="214.8"></rect>
          <text
            id="go1TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="970.2661581039429"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1299" dy="45.5" x="970.2661581039429">1</tspan>
          </text>
          <text
            id="SvgjsText1300"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="970.2661581039429"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,730.2000169754031,1229.800015449524)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1301" dy="45.5" x="970.2661581039429">1</tspan>
          </text>
          <rect id="go33" width="70" height="70" class="black" x="1015" y="214.8"></rect>
          <text
            id="go33TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1304" dy="45.5" x="1030.0561752319336">33</tspan>
          </text>
          <text
            id="SvgjsText1305"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1030.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,800.200004577637,1299.8000030517578)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1306" dy="45.5" x="1030.0561752319336">33</tspan>
          </text>
          <rect id="go16" width="70" height="70" class="red" x="1085" y="214.8"></rect>
          <text
            id="go16TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1100.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1309" dy="45.5" x="1100.0561752319336">16</tspan>
          </text>
          <text
            id="SvgjsText1310"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1100.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,870.200004577637,1369.8000030517578)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1311" dy="45.5" x="1100.0561752319336">16</tspan>
          </text>
          <rect id="go24" width="70" height="70" class="black" x="1155" y="214.8"></rect>
          <text
            id="go24TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="216.21464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1314" dy="45.5" x="1170.0561752319336">24</tspan>
          </text>
          <text
            id="SvgjsText1315"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1170.0561752319336"
            y="216.21464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,940.200004577637,1439.8000030517578)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1316" dy="45.5" x="1170.0561752319336">24</tspan>
          </text>
          <path
            id="go5"
            d="M1225.375 214.62000274658203C1225.205 214.62000274658203 1225.045 214.61000274658204 1224.875 214.61000274658204V284.62000274658203C1225.045 284.62000274658203 1225.205 284.630002746582 1225.375 284.630002746582C1263.915 284.630002746582 1298.805 269.06000274658203 1324.125 243.87000274658203L1274.625 194.37000274658203C1261.975 206.88000274658202 1244.575 214.62000274658203 1225.375 214.62000274658203Z "
            class="red"
          ></path>
          <text
            id="go5TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="205.91464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1319" dy="45.5" x="1259.7874450683594">5</tspan>
          </text>
          <text
            id="SvgjsText1320"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="205.91464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1030.5000343322756,1509.5000267028809)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1321" dy="45.5" x="1259.7874450683594">5</tspan>
          </text>
          <path
            id="go10"
            d="M1365.375009765625 145.13999725341796H1295.375009765625V145.12999725341797C1295.235009765625 164.38999725341796 1287.325009765625 181.78999725341797 1274.625009765625 194.36999725341798L1324.125009765625 243.86999725341798C1349.495009765625 218.62999725341797 1365.235009765625 183.71999725341797 1365.375009765625 145.13999725341796Z "
            class="black"
          ></path>
          <text
            id="go10TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1300.0561752319336"
            y="156.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1324" dy="45.5" x="1300.0561752319336">10</tspan>
          </text>
          <text
            id="SvgjsText1325"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1300.0561752319336"
            y="156.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1130.0000076293948,1510)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1326" dy="45.5" x="1300.0561752319336">10</tspan>
          </text>
          <path
            id="go23"
            d="M1365.2550048828125 145.239990234375C1365.2550048828125 106.57999023437503 1349.5850048828124 71.57999023437503 1324.2450048828125 46.24999023437499L1274.7450048828125 95.74999023437499C1287.4150048828126 108.419990234375 1295.2450048828125 125.919990234375 1295.2450048828125 145.249990234375C1295.2450048828125 145.419990234375 1295.2350048828125 145.57999023437497 1295.2350048828125 145.749990234375H1365.2450048828125C1365.2450048828125 145.56999023437498 1365.2550048828125 145.40999023437502 1365.2550048828125 145.239990234375Z "
            class="red"
          ></path>
          <text
            id="go23TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1307.0561752319336"
            y="67.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1329" dy="45.5" x="1307.0561752319336">23</tspan>
          </text>
          <text
            id="SvgjsText1330"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1307.0561752319336"
            y="67.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1226.0000782012942,1428.0000705718994)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1331" dy="45.5" x="1307.0561752319336">23</tspan>
          </text>
          <path
            id="go8"
            d="M1225.25 5.144995117187477C1225.08 5.144995117187477 1224.92 5.154995117187468 1224.75 5.154995117187468V75.16499511718746C1224.92 75.16499511718746 1225.08 75.15499511718747 1225.25 75.15499511718747C1244.58 75.15499511718747 1262.08 82.99499511718744 1274.75 95.65499511718747L1324.25 46.15499511718747C1298.91 20.814995117187493 1263.91 5.144995117187477 1225.25 5.144995117187477Z "
            class="black"
          ></path>
          <text
            id="go8TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="16.81464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1334" dy="45.5" x="1259.7874450683594">8</tspan>
          </text>
          <text
            id="SvgjsText1335"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1259.7874450683594"
            y="16.81464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1219.600030899048,1320.4000301361084)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1336" dy="45.5" x="1259.7874450683594">8</tspan>
          </text>
          <path
            id="go3"
            d="M125.5 193.8L76 243.3C101.3 268.8 136.3 284.7 175 284.8V214.8C155.6 214.60000000000002 138.1 206.60000000000002 125.5 193.8Z "
            class="red"
          ></path>
          <text
            id="go3TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="117.78744506835938"
            y="205.71464614868165"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1339" dy="45.5" x="117.78744506835938">3</tspan>
          </text>
          <text
            id="SvgjsText1340"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="117.78744506835938"
            y="205.71464614868165"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-111.29998397827146,367.29998397827154)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1341" dy="45.5" x="117.78744506835938">3</tspan>
          </text>
          <path
            id="go26"
            d="M105.5 144.5H35.5C35.5 144.7 35.5 144.8 35.5 145C35.5 183.4 51 218.2 76 243.5L125.5 194C113.1 181.4 105.5 164.1 105.5 145C105.5 144.8 105.5 144.7 105.5 144.5Z "
            class="black"
          ></path>
          <text
            id="go26TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="60.556175231933594"
            y="156.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1344" dy="45.5" x="60.556175231933594">26</tspan>
          </text>
          <text
            id="SvgjsText1345"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="60.556175231933594"
            y="156.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,-109.4999961853027,270.49999618530273)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1346" dy="45.5" x="60.556175231933594">26</tspan>
          </text>
          <path
            id="go0"
            d="M175.05 74.95V4.950000000000003C136.55 5.0500000000000025 101.75 20.750000000000004 76.55 45.95S35.65 105.95 35.55 144.45H105.55C105.64999999999999 125.35000000000001 113.45 108.05 126.05 95.45L126.05 95.45C138.64999999999998 82.95 155.95 75.05 175.05 74.95Z "
            class="green"
          ></path>
          <text
            id="go0TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="95.08744506835937"
            y="41.11464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1349" dy="45.5" x="95.08744506835937">0</tspan>
          </text>
          <text
            id="SvgjsText1350"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="95.08744506835937"
            y="41.11464614868164"
            class="txtOvalePortrait"
            transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,30.60000610351564,180)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1351" dy="45.5" x="95.08744506835937">0</tspan>
          </text>
          <path
            id="goS2"
            d="M1016 197.5H1226V197.5C1254.8 197.2 1278 173.8 1278 145S1254.8 92.8 1226 92.5V92.5H876L1016 197.5Z "
            class="transparent"
          ></path>
          <text
            id="goS2TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1000.8238830566406"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1354" dy="45.5" x="1000.8238830566406">SERIE 5/8</tspan>
          </text>
          <text
            id="SvgjsText1355"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="1000.8238830566406"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,0,-2.2737367544323206e-13)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1356" dy="45.5" x="1000.8238830566406">SERIE 5/8</tspan>
          </text>
          <path
            id="goOR"
            d="M666 92.5H876L1016 197.5H666V92.5Z "
            class="transparent"
          ></path>
          <text
            id="goORTO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="708.6328506469727"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1359" dy="45.5" x="708.6328506469727">ORPHELINS</tspan>
          </text>
          <text
            id="SvgjsText1360"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="708.6328506469727"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,0,-1.7053025658242404e-13)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1361" dy="45.5" x="708.6328506469727">ORPHELINS</tspan>
          </text>
          <rect
            id="goS1"
            width="350"
            height="105"
            class="transparent"
            x="316"
            y="92.5"
          ></rect>
          <text
            id="goS1TO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="394.2312469482422"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1364" dy="45.5" x="394.2312469482422">SERIE 0/2/3</tspan>
          </text>
          <text
            id="SvgjsText1365"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="394.2312469482422"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,5.684341886080802e-14,-8.526512829121202e-14)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1366" dy="45.5" x="394.2312469482422">SERIE 0/2/3</tspan>
          </text>
          <path
            id="goVI"
            d="M176 92.51V92.5H316V197.5H176V197.49C147.24 197.22 124 173.83 124 145S147.24 92.78 176 92.51Z "
            class="transparent"
          ></path>
          <text
            id="goVITO"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="169.68188858032227"
            y="111.41464614868164"
            class="txtOvaleLandscape"
          >
            <tspan id="SvgjsTspan1369" dy="45.5" x="169.68188858032227">ZERO</tspan>
          </text>
          <text
            id="SvgjsText1370"
            font-family="'Helvetica'"
            font-size="36"
 font-weight="normal"
            fill="#ffffff"
            family="'Helvetica'"
            size="36"
            weight="100"
            x="169.68188858032227"
            y="111.41464614868164"
            class="txtOvalePortrait"
            transform="matrix(1,1.9915985002059197e-16,-1.9915985002059197e-16,1,2.842170943040401e-14,-8.526512829121202e-14)"
            style="display: none"
          >
            <tspan id="SvgjsTspan1371" dy="45.5" x="169.68188858032227">ZERO</tspan>
          </text>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
  import { isMobile } from "../../utils/index.js";

  export default {
    data() {
      return {
        isMobile : false
      }
    },
    mounted() {
      this.isMobile = isMobile();
    }
  };
</script>
<style scoped>
.mobile-ovale{
transform: rotate(90deg) translateY(-40%) translateX(-50px);
  transform-origin:left bottom;
}
</style>
