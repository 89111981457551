const blackPoints = [
  
    {
        "width": "70",
        "x": "30",
        "id": "gPL0",
        "height": "300",
        "class": "green",
        "y": "0"
    },
    {
        "width": "100",
        "x": "100",
        "id": "gPL2",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "200",
        "id": "gPL4",
        "height": "100",
        "class": "black",
        "y": "200"
    },
    {
        "width": "100",
        "x": "200",
        "id": "gPL6",
        "height": "100",
        "class": "black",
        "y": "0"
    },
    {
        "width": "100",
        "x": "300",
        "id": "gPL8",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "400",
        "id": "gPL10",
        "height": "100",
        "class": "black",
        "y": "200"
    },
    {
        "width": "100",
        "x": "400",
        "id": "gPL11",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "500",
        "id": "gPL13",
        "height": "100",
        "class": "black",
        "y": "200"
    },
    {
        "width": "100",
        "x": "500",
        "id": "gPL15",
        "height": "100",
        "class": "black",
        "y": "0"
    },
    {
        "width": "100",
        "x": "600",
        "id": "gPL17",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "700",
        "id": "gPL20",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "800",
        "id": "gPL22",
        "height": "100",
        "class": "black",
        "y": "200"
    },
    {
        "width": "100",
        "x": "800",
        "id": "gPL24",
        "height": "100",
        "class": "black",
        "y": "0"
    },
    {
        "width": "100",
        "x": "900",
        "id": "gPL26",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "1000",
        "id": "gPL28",
        "height": "100",
        "class": "black",
        "y": "200"
    },
    {
        "width": "100",
        "x": "1000",
        "id": "gPL29",
        "height": "100",
        "class": "black",
        "y": "100"
    },
    {
        "width": "100",
        "x": "1100",
        "id": "gPL31",
        "height": "100",
        "class": "black",
        "y": "200"
    },
    {
        "width": "100",
        "x": "1100",
        "id": "gPL33",
        "height": "100",
        "class": "black",
        "y": "0"
    },
    {
        "width": "100",
        "x": "1200",
        "id": "gPL35",
        "height": "100",
        "class": "black",
        "y": "100"
    }
];
const redPoints = [
    {
        "width": "100",
        "x": "100",
        "id": "gPL1",
        "height": "100",
        "class": "red",
        "y": "200"
    },
    {
        "width": "100",
        "x": "100",
        "id": "gPL3",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "200",
        "id": "gPL5",
        "height": "100",
        "class": "red",
        "y": "100"
    },
    {
        "width": "100",
        "x": "300",
        "id": "gPL7",
        "height": "100",
        "class": "red",
        "y": "200"
    },
    {
        "width": "100",
        "x": "300",
        "id": "gPL9",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "400",
        "id": "gPL12",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "500",
        "id": "gPL14",
        "height": "100",
        "class": "red",
        "y": "100"
    },
    {
        "width": "100",
        "x": "600",
        "id": "gPL16",
        "height": "100",
        "class": "red",
        "y": "200"
    },
    {
        "width": "100",
        "x": "600",
        "id": "gPL18",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "700",
        "id": "gPL19",
        "height": "100",
        "class": "red",
        "y": "200"
    },
    {
        "width": "100",
        "x": "700",
        "id": "gPL21",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "800",
        "id": "gPL23",
        "height": "100",
        "class": "red",
        "y": "100"
    },
    {
        "width": "100",
        "x": "900",
        "id": "gPL25",
        "height": "100",
        "class": "red",
        "y": "200"
    },
    {
        "width": "100",
        "x": "900",
        "id": "gPL27",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "1000",
        "id": "gPL30",
        "height": "100",
        "class": "red",
        "y": "0"
    },
    {
        "width": "100",
        "x": "1100",
        "id": "gPL32",
        "height": "100",
        "class": "red",
        "y": "100"
    },
    {
        "width": "100",
        "x": "1200",
        "id": "gPL34",
        "height": "100",
        "class": "red",
        "y": "200"
    },
    {
        "width": "100",
        "x": "1200",
        "id": "gPL36",
        "height": "100",
        "class": "red",
        "y": "0"
    }
]
const controlTextPoints =[
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "306.9864101409912",
        "x": "262.39157485961914",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gD1T",
        "tspanId": "SvgjsTspan1144",
        "tspanDy": "39",
        "tspanX": "262.39157485961914",
        "text": "1st 12"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "306.9864101409912",
        "x": "653.3077583312988",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gD2T",
        "tspanId": "SvgjsTspan1147",
        "tspanDy": "39",
        "tspanX": "653.3077583312988",
        "text": "2nd 12"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "306.9864101409912",
        "x": "1056.7696228027344",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gD3T",
        "tspanId": "SvgjsTspan1150",
        "tspanDy": "39",
        "tspanX": "1056.7696228027344",
        "text": "3rd 12"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "376.9864101409912",
        "x": "165.0952606201172",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gMAT",
        "tspanId": "SvgjsTspan1153",
        "tspanDy": "39",
        "tspanX": "165.0952606201172",
        "text": "1 - 18"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "376.9864101409912",
        "x": "357.6422424316406",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPRT",
        "tspanId": "SvgjsTspan1156",
        "tspanDy": "39",
        "tspanX": "357.6422424316406",
        "text": "EVEN"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "376.9864101409912",
        "x": "962.6347618103027",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gDST",
        "tspanId": "SvgjsTspan1163",
        "tspanDy": "39",
        "tspanX": "962.6347618103027",
        "text": "ODD"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "376.9864101409912",
        "x": "1153.3378219604492",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPAT",
        "tspanId": "SvgjsTspan1166",
        "tspanDy": "39",
        "tspanX": "1153.3378219604492",
        "text": "19 - 36"
    },
]
const textPoints =[
  
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "48.44886016845703",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL0T",
        "tspanId": "SvgjsTspan1024",
        "tspanDy": "65",
        "tspanX": "48.44886016845703",
        "text": "0"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "140.05787181854248",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL1T",
        "tspanId": "SvgjsTspan1027",
        "tspanDy": "65",
        "tspanX": "140.05787181854248",
        "text": "1"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "135.79491519927979",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL2T",
        "tspanId": "SvgjsTspan1030",
        "tspanDy": "65",
        "tspanX": "135.79491519927979",
        "text": "2"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "134.97951793670654",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL3T",
        "tspanId": "SvgjsTspan1033",
        "tspanDy": "65",
        "tspanX": "134.97951793670654",
        "text": "3"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "233.47747039794922",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL4T",
        "tspanId": "SvgjsTspan1036",
        "tspanDy": "65",
        "tspanX": "233.47747039794922",
        "text": "4"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "235.85213661193848",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL5T",
        "tspanId": "SvgjsTspan1039",
        "tspanDy": "65",
        "tspanX": "235.85213661193848",
        "text": "5"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "234.43591976165771",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL6T",
        "tspanId": "SvgjsTspan1042",
        "tspanDy": "65",
        "tspanX": "234.43591976165771",
        "text": "6"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "335.26562213897705",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL7T",
        "tspanId": "SvgjsTspan1045",
        "tspanDy": "65",
        "tspanX": "335.26562213897705",
        "text": "7"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "334.04967880249023",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL8T",
        "tspanId": "SvgjsTspan1048",
        "tspanDy": "65",
        "tspanX": "334.04967880249023",
        "text": "8"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "334.7649393081665",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL9T",
        "tspanId": "SvgjsTspan1051",
        "tspanDy": "65",
        "tspanX": "334.7649393081665",
        "text": "9"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "423.5067310333252",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL10T",
        "tspanId": "SvgjsTspan1054",
        "tspanDy": "65",
        "tspanX": "423.5067310333252",
        "text": "10"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "431.03127670288086",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL11T",
        "tspanId": "SvgjsTspan1057",
        "tspanDy": "65",
        "tspanX": "431.03127670288086",
        "text": "11"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "425.85278701782227",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL12T",
        "tspanId": "SvgjsTspan1060",
        "tspanDy": "65",
        "tspanX": "425.85278701782227",
        "text": "12"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "525.9529228210449",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL13T",
        "tspanId": "SvgjsTspan1063",
        "tspanDy": "65",
        "tspanX": "525.9529228210449",
        "text": "13"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "523.5353412628174",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL14T",
        "tspanId": "SvgjsTspan1066",
        "tspanDy": "65",
        "tspanX": "523.5353412628174",
        "text": "14"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "525.9100074768066",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL15T",
        "tspanId": "SvgjsTspan1069",
        "tspanDy": "65",
        "tspanX": "525.9100074768066",
        "text": "15"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "624.4975357055664",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL16T",
        "tspanId": "SvgjsTspan1072",
        "tspanDy": "65",
        "tspanX": "624.4975357055664",
        "text": "16"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "625.3377990722656",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL17T",
        "tspanId": "SvgjsTspan1075",
        "tspanDy": "65",
        "tspanX": "625.3377990722656",
        "text": "17"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "624.1075496673584",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL18T",
        "tspanId": "SvgjsTspan1078",
        "tspanDy": "65",
        "tspanX": "624.1075496673584",
        "text": "18"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "724.8371162414551",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL19T",
        "tspanId": "SvgjsTspan1081",
        "tspanDy": "65",
        "tspanX": "724.8371162414551",
        "text": "19"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "719.3009967803955",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL20T",
        "tspanId": "SvgjsTspan1084",
        "tspanDy": "65",
        "tspanX": "719.3009967803955",
        "text": "20"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "726.7683219909668",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL21T",
        "tspanId": "SvgjsTspan1087",
        "tspanDy": "65",
        "tspanX": "726.7683219909668",
        "text": "21"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "821.6041374206543",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL22T",
        "tspanId": "SvgjsTspan1090",
        "tspanDy": "65",
        "tspanX": "821.6041374206543",
        "text": "22"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "821.704273223877",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL23T",
        "tspanId": "SvgjsTspan1093",
        "tspanDy": "65",
        "tspanX": "821.704273223877",
        "text": "23"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "819.8445949554443",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL24T",
        "tspanId": "SvgjsTspan1096",
        "tspanDy": "65",
        "tspanX": "819.8445949554443",
        "text": "24"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "921.6470527648926",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL25T",
        "tspanId": "SvgjsTspan1099",
        "tspanDy": "65",
        "tspanX": "921.6470527648926",
        "text": "25"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "920.2934494018555",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL26T",
        "tspanId": "SvgjsTspan1102",
        "tspanDy": "65",
        "tspanX": "920.2934494018555",
        "text": "26"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "921.1320648193359",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL27T",
        "tspanId": "SvgjsTspan1105",
        "tspanDy": "65",
        "tspanX": "921.1320648193359",
        "text": "27"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "1019.8445949554443",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL28T",
        "tspanId": "SvgjsTspan1108",
        "tspanDy": "65",
        "tspanX": "1019.8445949554443",
        "text": "28"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "1020.5741596221924",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL29T",
        "tspanId": "SvgjsTspan1111",
        "tspanDy": "65",
        "tspanX": "1020.5741596221924",
        "text": "29"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "1018.4283771514893",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL30T",
        "tspanId": "SvgjsTspan1114",
        "tspanDy": "65",
        "tspanX": "1018.4283771514893",
        "text": "30"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "1125.952922821045",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL31T",
        "tspanId": "SvgjsTspan1117",
        "tspanDy": "65",
        "tspanX": "1125.952922821045",
        "text": "31"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "1120.9031810760498",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL32T",
        "tspanId": "SvgjsTspan1120",
        "tspanDy": "65",
        "tspanX": "1120.9031810760498",
        "text": "32"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "1121.0033168792725",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL33T",
        "tspanId": "SvgjsTspan1123",
        "tspanDy": "65",
        "tspanX": "1121.0033168792725",
        "text": "33"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "202.39514350891113",
        "x": "1218.4569873809814",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL34T",
        "tspanId": "SvgjsTspan1126",
        "tspanDy": "65",
        "tspanX": "1218.4569873809814",
        "text": "34"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "102.39514350891113",
        "x": "1221.0891494750977",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL35T",
        "tspanId": "SvgjsTspan1129",
        "tspanDy": "65",
        "tspanX": "1221.0891494750977",
        "text": "35"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "50",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "50",
        "y": "2.395143508911133",
        "x": "1219.4237365722656",
        "transform": "matrix(1,0,0,1,0,0)",
        "id": "gPL36T",
        "tspanId": "SvgjsTspan1132",
        "tspanDy": "65",
        "tspanX": "1219.4237365722656",
        "text": "36"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "21.98641014099121",
        "x": "1297.9923934936523",
        "transform": "matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1285.0000362396243,1385.0000400543213)",
        "id": "gC3T",
        "tspanId": "SvgjsTspan1135",
        "tspanDy": "39",
        "tspanX": "1297.9923934936523",
        "text": "2 to 1"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "121.98641014099121",
        "x": "1297.9923934936523",
        "transform": "matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1185.0000247955325,1485.000051498413)",
        "id": "gC2T",
        "tspanId": "SvgjsTspan1138",
        "tspanDy": "39",
        "tspanX": "1297.9923934936523",
        "text": "2 to 1"
    },
    {
        "fontFamily": "'Montserrat', sans-serif",
        "fontSize": "30",
        "fill": "#ffffff",
        "family": "'Montserrat', sans-serif",
        "size": "30",
        "y": "221.9864101409912",
        "x": "1297.9923934936523",
        "transform": "matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,1085.0000400543215,1585.000036239624)",
        "id": "gC1T",
        "tspanId": "SvgjsTspan1141",
        "tspanDy": "39",
        "tspanX": "1297.9923934936523",
        "text": "2 to 1"
    },
  
]
export { blackPoints, redPoints,textPoints,controlTextPoints };