<template>
  <div
    class="coin w-10 h-10 md:w-12 md:h-12"
    @click="handleSelectCoin"
    :class="{ 'coin-selected': this.value == this.$store.state.coin }"
  >
    <!-- <svg version="1.0"
        xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1371.000000 1367.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1371.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
            <circle cx="6850" cy="6835" r="6850"></circle>
        </g>
        <g transform="translate(0.000000,1367.000000) scale(0.100000,-0.100000)" v-bind:fill="fillColor" stroke="none">
            <path d="M8843 13247 c-23 -34 -86 -127 -141 -207 -325 -474 -455 -667 -459 -683 -12 -40 235 -305 487 -522 347 -300 843 -653 1192 -849 156 -88 399 -195 522 -230 165 -48 360 -59 431 -25 22 10 95 110 279 379 136 201 286 420 332 488 46 67 84 126 84 130 0 4 -64 66 -142 138 -507 466 -985 807 -1503 1075 -317 163 -615 280 -880 345 -149 36 -152 35 -202 -39z"/>
            <path d="M4495 13216 c-770 -289 -1455 -677 -1945 -1102 -177 -153 -364 -346 -438 -452 l-23 -32 69 -123 c38 -67 108 -194 157 -282 48 -88 118 -214 155 -280 37 -66 88 -159 114 -207 26 -49 54 -88 61 -88 29 0 165 32 280 66 384 113 1164 494 1590 776 322 213 541 423 634 610 63 125 63 135 -12 269 -100 179 -384 690 -461 832 -20 37 -38 67 -39 66 -1 0 -65 -24 -142 -53z"/>
            <path d="M6607 11593 c-4 -3 -7 -37 -7 -73 l0 -68 333 -5 c322 -5 429 -14 682 -52 104 -16 297 -52 328 -61 15 -5 17 3 17 70 l0 75 -37 10 c-171 43 -434 81 -687 101 -144 11 -619 14 -629 3z"/>
            <path d="M5700 11461 c-199 -48 -235 -59 -475 -143 -193 -68 -575 -234 -603 -262 -12 -11 -11 -23 3 -75 10 -33 22 -61 26 -61 5 0 58 24 117 54 269 134 707 288 1027 361 66 15 122 29 124 31 2 2 -5 33 -14 69 -13 47 -22 65 -34 64 -9 0 -86 -18 -171 -38z"/>
            <path d="M6560 11349 c-854 -58 -1640 -328 -2345 -807 -757 -515 -1357 -1261 -1688 -2102 -227 -576 -330 -1154 -314 -1765 12 -433 52 -708 167 -1125 76 -274 170 -519 303 -785 518 -1041 1429 -1859 2525 -2268 231 -87 278 -101 517 -161 441 -110 747 -142 1250 -133 317 6 474 20 715 63 726 130 1399 420 1980 853 869 645 1465 1541 1728 2596 81 324 111 568 119 960 9 509 -35 879 -158 1313 -63 222 -86 289 -165 485 -420 1036 -1225 1900 -2234 2396 -748 369 -1580 535 -2400 480z"/>
            <path d="M8655 11193 c-20 -35 -35 -66 -33 -67 1 -1 71 -31 153 -65 281 -119 612 -305 877 -494 l148 -105 35 61 c27 48 32 65 22 73 -29 28 -246 179 -369 256 -225 142 -475 272 -730 378 l-67 29 -36 -66z"/>
            <path d="M3865 10575 c-294 -228 -627 -556 -832 -820 l-31 -41 31 -38 32 -37 75 64 c42 36 97 91 124 123 151 186 548 551 764 703 18 13 32 27 30 32 -4 13 -72 98 -78 99 -3 0 -55 -38 -115 -85z"/>
            <path d="M10257 10166 c-26 -18 -50 -36 -54 -40 -3 -3 32 -45 78 -94 275 -286 493 -568 677 -872 28 -47 56 -91 60 -98 13 -20 125 55 117 77 -27 69 -281 444 -419 616 -131 165 -388 446 -405 444 -3 0 -28 -15 -54 -33z"/>
            <path d="M2643 9188 c-61 -98 -196 -365 -266 -529 -94 -220 -126 -308 -232 -660 -4 -11 10 -22 51 -38 31 -13 59 -20 64 -15 4 5 20 54 35 108 85 315 265 741 435 1026 l59 101 -56 24 c-30 14 -57 25 -59 25 -2 0 -16 -19 -31 -42z"/>
            <path d="M11313 8578 c-26 -5 -43 -15 -43 -23 0 -8 21 -65 46 -127 56 -139 151 -455 193 -643 18 -77 45 -228 60 -335 15 -107 32 -195 37 -195 36 1 125 18 129 24 7 13 -32 268 -71 461 -51 250 -133 524 -240 803 -20 52 -27 54 -111 35z"/>
            <path d="M12357 8342 c-24 -46 -75 -210 -107 -341 -101 -419 -182 -1238 -166 -1691 14 -422 84 -730 202 -890 24 -32 52 -63 62 -69 10 -5 54 -13 98 -16 130 -9 748 -44 906 -51 l146 -7 11 39 c29 113 95 503 126 739 64 498 71 1046 19 1468 -30 245 -95 546 -151 702 l-29 80 -105 6 c-57 4 -160 9 -229 13 -274 14 -658 36 -712 41 -55 4 -58 3 -71 -23z"/>
            <path d="M121 7935 c-117 -670 -131 -1504 -35 -2094 51 -316 107 -541 185 -742 l50 -129 554 0 553 0 26 83 c96 302 156 886 156 1513 0 308 -9 461 -41 689 -50 360 -155 629 -281 722 l-32 23 -562 0 -562 0 -11 -65z"/>
            <path d="M2011 7232 c-26 -49 -34 -623 -12 -872 7 -74 23 -209 37 -300 13 -91 24 -166 24 -167 0 -6 127 -3 132 3 3 3 2 22 -3 42 -5 20 -21 129 -36 242 -26 192 -27 229 -28 580 0 206 3 401 8 433 l7 57 -60 0 c-47 0 -61 -4 -69 -18z"/>
            <path d="M11606 6428 c-25 -355 -99 -730 -218 -1096 -22 -68 -38 -126 -35 -128 7 -7 117 -25 133 -22 42 8 176 520 228 868 17 114 46 382 46 428 0 27 -4 30 -42 36 -24 4 -57 9 -74 12 l-31 6 -7 -104z"/>
            <path d="M2328 5169 c-32 -16 -58 -35 -58 -42 0 -13 42 -120 110 -282 65 -155 192 -399 294 -569 105 -173 216 -336 228 -336 12 0 128 59 128 66 0 3 -40 62 -88 132 -187 271 -384 635 -492 912 -29 74 -56 138 -59 142 -3 3 -32 -7 -63 -23z"/>
            <path d="M11091 4633 c-135 -274 -381 -631 -627 -914 l-93 -106 63 -46 c35 -26 68 -47 73 -47 31 0 361 427 504 651 79 125 236 402 254 450 5 12 -10 28 -52 58 -32 22 -63 41 -68 41 -6 0 -30 -39 -54 -87z"/>
            <path d="M3331 3529 l-43 -59 46 -55 c59 -69 307 -296 452 -413 173 -141 521 -382 551 -382 5 0 31 30 58 66 52 71 52 67 -9 100 -59 32 -304 199 -412 280 -153 116 -316 256 -465 401 -69 68 -128 123 -130 123 -2 0 -24 -28 -48 -61z"/>
            <path d="M9965 3229 c-214 -180 -475 -362 -725 -504 -167 -95 -307 -182 -316 -197 -8 -13 69 -128 86 -128 23 0 309 156 460 250 173 109 354 238 504 360 174 141 164 123 111 203 -25 37 -49 67 -53 67 -4 0 -34 -23 -67 -51z"/>
            <path d="M11000 2939 c-435 -98 -1367 -552 -1885 -919 -316 -224 -532 -465 -569 -637 -8 -33 -5 -38 284 -553 51 -91 126 -226 167 -300 41 -74 77 -137 79 -139 5 -6 268 97 470 184 763 331 1403 743 1849 1190 111 111 225 244 225 262 0 6 -36 75 -81 154 -122 216 -218 388 -308 549 -109 197 -129 230 -138 229 -5 0 -46 -9 -93 -20z"/>
            <path d="M3154 2620 c-99 -14 -89 -1 -336 -434 -276 -484 -328 -577 -328 -587 0 -16 255 -213 475 -366 766 -534 1556 -879 2192 -958 l52 -7 22 44 c12 23 45 84 74 133 46 78 323 565 413 726 18 31 32 60 32 64 0 15 -159 171 -265 260 -191 159 -416 319 -703 497 -758 470 -1294 677 -1628 628z"/>
            <path d="M4991 2423 c-6 -21 -14 -58 -20 -81 l-9 -44 54 -24 c84 -37 357 -132 489 -171 243 -70 746 -167 764 -147 5 5 18 44 29 87 l20 78 -25 10 c-13 5 -34 9 -46 9 -45 0 -325 50 -467 84 -226 53 -388 101 -577 171 -96 36 -181 65 -188 65 -8 0 -19 -17 -24 -37z"/>
            <path d="M8030 2245 c-309 -80 -535 -114 -911 -137 l-201 -12 7 -85 c4 -47 9 -90 12 -95 7 -10 236 0 433 20 245 24 575 86 828 154 l83 23 -5 36 c-3 20 -8 62 -11 94 -4 35 -11 56 -18 56 -7 -1 -104 -25 -217 -54z"/>
        </g>
        <filter :id="filter">
          <feDropShadow dx="0" dy="0" stdDeviation="500"
              flood-color="black"/>
        </filter>
        <g fill="#fff" stroke="none" transform="translate(0.000000,1367.000000) scale(0.100000,0.100000)" :filter="`url(#${filter})`">
            <text letter-spacing="-10" font-size="5000" x="6850" y="-5000" text-anchor="middle">{{ value >= 1000 ? `${value / 1000}K` : value }}</text>
        </g>
    </svg> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 368.5 373.5"
      enable-background="new 0 0 368.5 373.5"
      xml:space="preserve"
    >
      <path
        class="color"
        v-bind:fill="fillColor"
        d="M368.5,184.25c0,101.76-82.49,184.25-184.25,184.25S0,286.01,0,184.25S82.49,0,184.25,0         S368.5,82.49,368.5,184.25z"
      />
      <path
        class="white"
        fill="#FFFFFF"
        d="M317.22,219.51c2.99-11.26,4.591-23.07,4.591-35.26c0-13.72-2.03-26.97-5.78-39.48         c12.71-2.52,25.1-5.38,37.83-8.25c-5.49-15.17-12.34-29.3-20.391-42.67c-8.1-13.35-17.42-25.93-28.329-37.69         c-8.431,9.91-17.33,20.14-25.08,29.49c-17.051-16.57-38.36-28.77-62.11-34.77c4-11.51,8.15-24.5,12.29-36.91         c-15.71-3.23-31.3-4.68-46.91-4.69c-15.609,0.05-31.25,1.51-47.07,4.68c4.221,12.62,8.261,24.92,12.801,37.31         c-25.221,6.68-47.61,20.36-64.98,38.83c0.311-0.36,0.62-0.72,0.94-1.07c-8.12-9.25-17.5-19.39-26.37-29.23         C48.09,71.88,39.16,84.73,31.45,98.31c-7.66,13.6-14.09,27.93-19.13,43.26c13.09,2.55,25.81,5.1,38.88,7.27         c0.15-0.4,0.31-0.8,0.47-1.21c-3.229,11.66-4.96,23.94-4.96,36.62c0,13.689,2.021,26.92,5.761,39.41         c-0.061-0.141-0.11-0.27-0.171-0.41c-13,2.561-25.64,5.491-38.649,8.42c5.49,15.17,12.35,29.301,20.399,42.67         c8.101,13.35,17.421,25.92,28.33,37.689c8.53-10.039,17.561-20.41,25.38-29.859c16.921,16.68,38.12,29.02,61.78,35.199         c-4,11.5-8.14,24.461-12.27,36.852c15.71,3.229,31.3,4.68,46.91,4.689c15.609-0.051,31.25-1.51,47.069-4.68         c-4.16-12.43-8.149-24.561-12.6-36.77c24.899-6.432,47.08-19.682,64.439-37.631c7.98,9.07,17.12,18.949,25.78,28.561         c10.561-12.08,19.49-24.93,27.2-38.51c7.66-13.611,14.09-27.941,19.13-43.262C342.41,224.13,329.97,221.65,317.22,219.51z             M184.26,311.811c-70.34,0-127.56-57.221-127.56-127.561c0-70.34,57.22-127.55,127.56-127.55c70.341,0,127.551,57.22,127.551,127.55         C311.811,254.59,254.59,311.811,184.26,311.811z"
      />
      <text
        class="txt"
        transform="matrix(1 0 0 1 185 235)"
        font-family="Teko"
        font-size="160"
        fill="#ffffff"
        text-anchor="middle"
      >
      {{ value >= 1000 ? `${value / 1000}K` : value }}
      </text>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: "yellow",
    },
    value: {
      type: Number,
      default: 0.5,
    },
    maxValue: {
      type: Number,
      default: 1000,
    },
    filter: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleSelectCoin() {
      this.$store.commit("setSelectedCoin", this.value);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.coin {
  transition: transform;
  transition-duration: 0.5s;
}

.coin:hover,
.coin-selected {
  animation-duration: 3s;
  animation-name: scaleAnimation;
  animation-iteration-count: infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1.2, 1.2);
  }

  50% {
    transform: scale(1.3, 1.3);
  }

  100% {
    transform: scale(1.2, 1.2);
  }
}
</style>
