<template>
  <div class="cdk-overlay-container" id="logoutpage">
    <div
      class="cdk-global-overlay-wrapper"
      dir="ltr"
      style="justify-content: center; align-items: center"
    >
      <div
        id="cdk-overlay-0"
        class="cdk-overlay-pane message-dialog dialog-exit"
        style="max-width: 80vw; width: auto; position: static"
      >
        <div
          tabindex="0"
          class="cdk-visually-hidden cdk-focus-trap-anchor"
          aria-hidden="true"
        ></div>
        <div
          tabindex="-1"
          aria-modal="true"
          class="mat-dialog-container ng-tns-c29-1 ng-trigger ng-trigger-dialogContainer ng-star-inserted"
          id="mat-dialog-0"
          role="dialog"
          style="transform: none"
        >
          <div class="ng-star-inserted">
            <div class="mat-dialog-content">
              <div class="ico-message-dialog"></div>
              <div class="dealer-picture" style="display: none">
                <img
                  src="https://lobby.liveg24.com/dealers/521_voja_head.png"
                  data-xblocker="passed"
                  style="visibility: visible"
                />
              </div>
              <p>BACK TO LIVE GAMES</p>
            </div>
            <div
              mat-dialog-actions=""
              class="mat-dialog-actions ng-star-inserted"
              style="padding-left: 43%;"
            >
              <button
                mat-button=""
                mat-dialog-close=""
                id="undoBtnClose"
                type="button"
                @click="cancel"
              >
                CANCEL
              </button>
            </div>
            <!---->
            <div
              mat-dialog-actions=""
              class="mat-dialog-actions ng-star-inserted"
              style=""
            >
              <button mat-button="" class="closeActive" @click="handleExit">OK</button>
            </div>
            <!---->
          </div>
          <!---->
        </div>
        <div
          tabindex="0"
          class="cdk-visually-hidden cdk-focus-trap-anchor"
          aria-hidden="true"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Exit_View",
  props:{
        cancel:{
            type:Function,
        },
        handleExit: {
            type: Function,
        },
        
    },
};
</script>

<style scoped>
/*! CSS Used from: https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/styles.css?cc=1234567 */
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-pane {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  pointer-events: auto;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}
.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
.disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}
.disabled:hover {
  cursor: default !important;
  background-color: transparent !important;
  transform: scale(1) !important;
  -o-box-shadow: unset !important;
  box-shadow: unset !important;
}
.pevents-off {
  pointer-events: none;
}
.pevents-on {
  pointer-events: visible;
}
.hide {
  display: none !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-moz-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #333;
}
::-moz-scrollbar-track {
  background-color: #333;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
::-moz-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-button {
  background-color: rgba(0, 0, 0, 0.8);
}
::-moz-scrollbar-button {
  background-color: rgba(0, 0, 0, 0.8);
}
::-webkit-scrollbar-corner {
  background-color: #000;
}
::-moz-scrollbar-corner {
  background-color: #000;
}
* {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
body {
  overflow: hidden;
}
body {
  background-color: #000;
  margin: 0;
  color: #fff;
  height: 101%;
}
img {
  vertical-align: middle;
}
h1,
h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
#overlay-sfumatura {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.mlc-btn {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 100%;
  z-index: 1;
  border: 0.3vh solid #fff;
  display: inline-block;
  border-radius: 50%;
  transition: 0.2s;
  box-shadow: -2px -4px 12px rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.mlc-btn:hover {
  background-color: #666;
  -o-box-shadow: 0 0 1.5em 0 #fff;
  box-shadow: 0 0 1.5em 0 #fff;
}
.mlc-btn:active,
.mlc-btn:focus {
  outline: 0 !important;
}
.mlc-btn:active {
  transform: scale(0.9);
  -o-box-shadow: 0 0 1.5em 0 #fff;
  box-shadow: 0 0 1.5em 0 #fff;
  opacity: 0.8;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .mlc-btn {
    border-width: 0.17vw;
  }
}
button {
  border: 0 solid transparent;
  padding: 0;
  align-items: flex-start;
  text-align: center;
  cursor: default;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1.5em;
  transition: 0.5s;
  color: #f5f5f5;
  font-weight: 100;
  pointer-events: visible;
}
button:hover {
  color: #c90;
  cursor: pointer;
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
button:active,
button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
/*! CSS Used from: Embedded */
.mlc-container[_ngcontent-sph-c159] {
  position: absolute;
  width: 100vw;
  height: 56.25vw;
  background: #000;
  max-height: 100vh;
  max-width: 177.78vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}
.mlc-footer[_ngcontent-sph-c159],
.mlc-header[_ngcontent-sph-c159] {
  width: 100%;
  position: absolute;
  pointer-events: none;
}
.mlc-header[_ngcontent-sph-c159] {
  height: 15%;
  top: 0;
}
.mlc-footer[_ngcontent-sph-c159] {
  height: 10%;
  bottom: 0;
}
.mlc-main[_ngcontent-sph-c159] {
  position: absolute;
  top: 15%;
  width: 100%;
  height: 85%;
  text-align: center;
}
.mlc-main[_ngcontent-sph-c159] .mlc-left[_ngcontent-sph-c159],
.mlc-main[_ngcontent-sph-c159] .mlc-right[_ngcontent-sph-c159] {
  width: 16%;
  height: 85%;
  position: absolute;
  top: 0;
  margin-top: 2px;
  z-index: 1;
}
.mlc-main[_ngcontent-sph-c159] .mlc-left[_ngcontent-sph-c159] {
  left: 0;
}
.mlc-main[_ngcontent-sph-c159] .mlc-right[_ngcontent-sph-c159] {
  right: 0;
}
.mlc-box-container[_ngcontent-sph-c159],
.mlc-view[_ngcontent-sph-c159] {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: auto;
  pointer-events: none;
}
.mlc-view[_ngcontent-sph-c159] {
  z-index: 99;
}
.mlc-box-container[_ngcontent-sph-c159] {
  z-index: 98;
}
#mlc-video-div[_ngcontent-sph-c159] {
  display: block;
  overflow: hidden;
  background-color: #000;
  opacity: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: all 0.2s linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 0;
}
/*! CSS Used from: Embedded */
.infotavolo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 30%;
  margin-top: 3px;
  text-shadow: #000 0 0 1em;
  pointer-events: none;
}
.infotavolo h1 {
  color: #c00;
  font-size: 3vh;
  font-weight: 500;
  position: relative;
  margin: 0 0 0 2px;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infotavolo h1 {
    font-size: 1.6vw;
  }
}
.infotavolo h1 .game {
  color: #c00;
  text-transform: uppercase;
  position: absolute;
  top: 6%;
  text-shadow: #000 0 0 0.4em;
}
.desktop .infotavolo h1 .game {
  font-size: 1.4vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .desktop .infotavolo h1 .game {
    font-size: 0.8vw;
  }
}
.infotavolo h1 > i {
  color: #fff;
  font-size: 0.3em;
  font-style: normal;
}
.infotavolo h1 > span {
  color: #fff;
  font-size: 0.4em;
  text-transform: uppercase;
}
.infotavolo p {
  position: relative;
  font-size: 1vh;
  margin: 0 0 0 2px;
  color: #ccc;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infotavolo p {
    font-size: 0.6vw;
  }
}
/*! CSS Used from: Embedded */
.infogioco {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  text-shadow: #000 0 0 1em;
}
.infogioco .dati-gioco {
  font-weight: 300;
  text-align: right;
  color: #ccc;
  float: right;
  margin-right: 1vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .dati-gioco {
    margin-right: 0.6vw;
  }
}
.infogioco .dati-gioco p {
  font-size: 1.5vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .dati-gioco p {
    font-size: 0.85vw;
  }
}
.infogioco .dati-gioco p span {
  font-weight: 500;
  font-size: 1.5vh;
  line-height: 1.2;
  width: 100%;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .dati-gioco p span {
    font-size: 0.8vw;
  }
}
.infogioco .dati-gioco p span.time {
  display: inline-block;
  width: 3.2vw;
  text-align: left;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .dati-gioco p span.time {
    margin-right: 0.6vw;
  }
}
.infogioco .dati-gioco #eventId {
  font-size: 1.5vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .dati-gioco #eventId {
    font-size: 0.85vw;
  }
}
.infogioco .mlc-btn {
  height: 4vh;
  width: 4vh;
  margin-top: 3%;
  float: right;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .mlc-btn {
    height: 2vw;
    width: 2vw;
  }
}
.infogioco .mlc-btn.ico-exit {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/exit.svg);
}
.infogioco .tooltip-datigioco {
  border: 2px solid #00aeef;
  position: absolute;
  top: 50%;
  right: 2%;
  padding: 1vh 2vw;
  font-size: 1.5vh;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  box-shadow: 0 0 10px #000;
  display: none;
  width: auto;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .tooltip-datigioco {
    font-size: 0.85vw;
    padding: 1vw 1.5vh;
  }
}
.infogioco .tooltip-datigioco .triangolo {
  position: absolute;
  top: -1.1vh;
  left: 60%;
  width: 0;
  height: 0;
  line-height: 0;
  border-bottom: 1vh solid #00aeef;
  border-left: 0.8vh solid transparent;
  border-right: 0.8vh solid transparent;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .infogioco .tooltip-datigioco .triangolo {
    top: -0.8vw;
    border-bottom: 0.8vw solid #00aeef;
    border-left: 0.6vw solid transparent;
    border-right: 0.6vw solid transparent;
  }
}
/*! CSS Used from: Embedded */
#overlay-wheel {
  width: 15.95vh;
  height: 15.95vh;
  position: absolute;
  border-radius: 50%;
  margin-left: 14.4%;
  margin-top: -0.8%;
  z-index: 11;
  background: linear-gradient(270deg, #000 -2%, transparent 35%),
    linear-gradient(90deg, #000 -2%, transparent 35%),
    linear-gradient(0deg, #000 -20%, transparent 40%),
    linear-gradient(180deg, #000 -20%, #00000000 40%);
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #overlay-wheel {
    width: 9vw;
    height: 9vw;
  }
}
#window-wheel {
  width: 15.4vh;
  height: 15.4vh;
  box-shadow: 0 0 20px #000;
  position: absolute;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 15%;
  margin-top: 0;
  z-index: 10;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #window-wheel {
    width: 8.7vw;
    height: 8.7vw;
  }
}
#window-wheel h3 {
  height: 100%;
  width: 100%;
  font-size: 2vh;
  font-weight: 800;
  color: #fff;
  text-shadow: #fff 0 0 1em;
  position: absolute;
  top: -13%;
  padding-top: 37%;
  background: rgba(0, 0, 0, 0.8);
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #window-wheel h3 {
    font-size: 1.14vw;
  }
}
#window-wheel #wheel {
  width: 580%;
  height: 580%;
  position: absolute;
  top: -3%;
  left: -240%;
  z-index: 9;
}
#window-wheel #wheel .black {
  fill: #000;
  stroke-width: 0;
}
#window-wheel #wheel .red {
  fill: #c00;
  stroke-width: 0;
}
#window-wheel #wheel .green {
  fill: #390;
  stroke-width: 0;
}
#window-wheel #wheel svg text {
  font-family: Montserrat, sans-serif !important;
}
/*! CSS Used from: Embedded */
.settings {
  position: absolute;
  bottom: 0;
  margin-left: 2%;
  margin-bottom: -5%;
  pointer-events: visible;
}
.settings .button-menu {
  width: 6vh;
  height: 6vh;
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/menu.svg);
  background-size: 75%;
  position: absolute;
  bottom: 25%;
  left: 2%;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .settings .button-menu {
    height: 3.4vw;
    width: 3.4vw;
  }
}
/*! CSS Used from: Embedded */
#counter {
  width: 17.7vh;
  height: 17.7vh;
  position: absolute;
  margin-left: 11.3%;
  margin-top: -3.9%;
  z-index: 12;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #counter {
    width: 10vw;
    height: 10vw;
  }
}
/*! CSS Used from: Embedded */
#info-panel {
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.5) 15%,
    rgba(0, 0, 0, 0.5)
  );
  width: 26vh;
  height: 7.87vh;
  position: absolute;
  top: 3%;
  left: 53%;
  color: #fff;
  border-radius: 0 3vh 7vh 0;
  padding: 2vh 1vh;
  z-index: 0;
}
#info-panel h3,
#info-panel p {
  width: 83%;
  font-weight: 800;
  float: right;
}
#info-panel h3 {
  font-size: 2.5vh;
  margin-top: 0;
  margin-bottom: 1vh;
}
#info-panel p {
  font-size: 1.7vh;
  margin: 0.5%;
  font-weight: 400;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #info-panel {
    width: 14.82vw;
    height: 4.5vw;
    font-size: 1vw;
    border-radius: 0 1.71vw 3.99vw 0;
    padding: 1.14vw 0.57vw;
  }
  #info-panel h3 {
    font-size: 1.4vw;
    margin-bottom: 0.6vw;
  }
  #info-panel p {
    font-size: 1vw;
  }
}
.infopanel-fadeOutLeft {
  -webkit-animation-name: infopanel-fadeOutLeft;
  animation-name: infopanel-fadeOutLeft;
}
/*! CSS Used from: Embedded */
.ultimi-numeri {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 60%;
}
.desktop .ultimi-numeri {
  height: 65%;
  top: 23%;
  padding-top: 0;
  overflow-y: scroll;
  pointer-events: visible;
  scrollbar-width: none;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
.desktop .ultimi-numeri::-webkit-scrollbar {
  width: 0 !important;
}
.desktop .ultimi-numeri::-moz-scrollbar {
  width: 0 !important;
}
.ultimi-numeri .num {
  display: inline-block;
  width: 36%;
  text-align: center;
  margin: 0 18%;
}
.ultimi-numeri .num > span {
  background: #fff;
  border: 0.3vh solid #333;
  color: #fff;
  width: 4vh;
  height: 4vh;
  display: inline-block;
  border-radius: 50%;
  font-family: Montserrat, sans-serif;
  font-size: 2.3vh;
  line-height: 4vh;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 0 1em #666;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .ultimi-numeri .num > span {
    width: 2.3vw;
    height: 2.3vw;
    font-size: 1.4vw;
    line-height: 2.3vw;
    border: 0.17vw solid #333;
  }
}
.ultimi-numeri .num > span.red {
  background: #c00;
  float: left;
}
.ultimi-numeri .num > span.black {
  background: #000;
  float: right;
}
.mlc-left .ultimi-numeri {
  position: absolute;
}
/*! CSS Used from: Embedded */
.saldo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20%;
  height: 100%;
}
.saldo {
  pointer-events: visible;
}
.saldo .txt-saldo {
  text-align: left;
  width: 100%;
  position: absolute;
  left: 1vh;
  bottom: 10%;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .saldo .txt-saldo {
    left: 0.8vw;
  }
}
.saldo h3 {
  color: #969696;
  font-size: 1.6vh;
  margin: 0;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .saldo h3 {
    font-size: 0.8vw;
  }
}
.saldo h3 > .valuta {
  color: #d8a100;
}
.saldo p {
  margin: 0 0 3px;
  padding: 0;
  font-size: 2.1vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .saldo p {
    font-size: 1.2vw;
  }
}
/*! CSS Used from: Embedded */
.rebet {
  position: absolute;
}
.rebet .mlc-btn {
  pointer-events: visible;
}
.rebet .mlc-btn.btn-rebet {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/rebet.svg);
  background-size: 65%;
  background-position: 55%;
}
.desktop .rebet {
  left: unset;
  right: 45%;
  top: 8%;
}
.desktop .rebet .mlc-btn {
  width: 7vh;
  height: 7vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .desktop .rebet .mlc-btn {
    width: 4vw;
    height: 4vw;
  }
}
/*! CSS Used from: Embedded */
.gettoniera {
  width: auto;
  height: 70%;
  text-align: center;
  position: absolute;
  top: 10%;
  right: 16%;
  pointer-events: visible;
}
.gettoniera svg {
  width: auto;
  margin-left: 0;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: 0.3s;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.gettoniera svg:active,
.gettoniera svg:focus,
.gettoniera svg:hover {
  outline: 0 !important;
}
.gettoniera svg.active {
  transform: scale3d(1.5, 1.5, 1.5);
  z-index: 10;
}
.gettoniera svg:active,
.gettoniera svg:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
.gettoniera svg text {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
}
/*! CSS Used from: Embedded */
.btn-undo-container {
  position: absolute;
  border-radius: 50%;
  pointer-events: visible;
}
.btn-undo-container .mlc-btn {
  position: absolute;
}
.btn-undo-container .mlc-btn.btn-undo-base {
  z-index: 21;
}
.btn-undo-container .mlc-btn.btn-undo-sub {
  z-index: 19;
}
.btn-undo-container .ico-undo-base {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/del.svg);
}
.btn-undo-container .ico-undo-all {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/clear_all.svg);
  background-size: 55%;
}
.desktop .btn-undo-container {
  left: unset;
  right: 55%;
  top: 8%;
}
.desktop .btn-undo-container .mlc-btn.btn-undo-base {
  width: 7vh;
  height: 7vh;
}
.desktop .btn-undo-container .mlc-btn.btn-undo-sub {
  width: 4vh;
  height: 4vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .desktop .btn-undo-container .mlc-btn.btn-undo-base {
    width: 4vw;
    height: 4vw;
  }
  .desktop .btn-undo-container .mlc-btn.btn-undo-sub {
    width: 2.3vw;
    height: 2.3vw;
  }
}
/*! CSS Used from: Embedded */
.puntata {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 100%;
}
.puntata .txt-puntata {
  text-align: right;
  width: 100%;
  position: absolute;
  right: 1vh;
  bottom: 10%;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .puntata .txt-puntata {
    right: 0.8vw;
  }
}
.puntata .txt-puntata h3 {
  color: #969696;
  font-size: 1.6vh;
  margin: 0;
  text-transform: uppercase;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .puntata .txt-puntata h3 {
    font-size: 0.8vw;
  }
}
.puntata .txt-puntata h3 > .valuta {
  color: #d8a100;
}
.puntata .txt-puntata p {
  margin: 0 0 3px;
  padding: 0;
  font-size: 2.1vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .puntata .txt-puntata p {
    font-size: 1.2vw;
  }
}
/*! CSS Used from: Embedded */
.roulette-left #limiti {
  left: 9%;
}
#limiti {
  width: 10%;
  height: auto;
  position: absolute;
  bottom: 7%;
  z-index: 1;
}
#limiti .txt-limiti tspan {
  text-transform: uppercase;
  text-shadow: #000 0 0 1px;
  transition: 0.2s;
}
#limiti:hover {
  cursor: pointer;
}
/*! CSS Used from: Embedded */
.panno-container #fiche,
.panno-container #ovale,
.panno-container #ovalesnap,
.panno-container #panno,
.panno-container #pda,
.panno-container #snap {
  position: absolute;
  height: inherit;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.panno-container #fiche,
.panno-container #ovale,
.panno-container #panno,
.panno-container #pda,
.panno-container #snap {
  pointer-events: none;
}
.panno-container #ovale,
.panno-container #panno {
  opacity: 0.85;
}
.panno-container #ovale text,
.panno-container #panno text {
  text-shadow: #000 0 0 40px;
}
.panno-container .snap-point {
  opacity: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  pointer-events: visible;
}
.panno-container .snap-point:active,
.panno-container .snap-point:focus {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.panno-container .snap-point:hover {
  opacity: 0.6;
  fill-opacity: 0;
}
/*! CSS Used from: Embedded */
.panno-container .red {
  fill: #c00;
  stroke: #fff;
  stroke-width: 2;
}
.panno-container .black {
  fill: #000;
  stroke: #fff;
  stroke-width: 2;
}
.panno-container .green {
  fill: #390;
  stroke: #fff;
  stroke-width: 2;
}
.panno-container .transparent {
  fill: #333;
  stroke: #fff;
  stroke-width: 2;
}
/*! CSS Used from: Embedded */
.panno-container .red {
  fill: #c00;
  stroke: #fff;
  stroke-width: 2;
}
.panno-container .black {
  fill: #000;
  stroke: #fff;
  stroke-width: 2;
}
.panno-container .green {
  fill: #390;
  stroke: #fff;
  stroke-width: 2;
}
.panno-container .transparent {
  fill: #333;
  stroke: #fff;
  stroke-width: 2;
}
/*! CSS Used from: Embedded */
#tooltip {
  position: absolute;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 2vh;
  border-radius: 1vh;
  z-index: 99999999;
  box-sizing: border-box;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  display: none;
}
#tooltip i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}
#tooltip i:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}
#tooltip .tooltip-title {
  border-radius: 1vh 1vh 0 0;
  padding: 0.8vh;
}
#tooltip .tooltip-title h3 {
  margin: 0;
}
#tooltip .tooltip-body {
  padding: 1vh;
}
.BETCLOSE #tooltip {
  display: none !important;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #tooltip {
    font-size: 1.14vw;
    border-radius: 0.57vw;
  }
  #tooltip .tooltip-title {
    border-radius: 0.57vw 0.57vw 0 0;
    padding: 0.46vw;
  }
  #tooltip .tooltip-body {
    padding: 0.57vw;
  }
}
/*! CSS Used from: Embedded */
.desktop.roulette-left .panno-container #fiche,
.desktop.roulette-left .panno-container #panno,
.desktop.roulette-left .panno-container #pda,
.desktop.roulette-left .panno-container #snap {
  left: 40%;
  width: 58%;
  height: auto;
  bottom: 12%;
}
.RL_GREEN .desktop.roulette-left .panno-container #fiche,
.RL_GREEN .desktop.roulette-left .panno-container #panno,
.RL_GREEN .desktop.roulette-left .panno-container #pda,
.RL_GREEN .desktop.roulette-left .panno-container #snap {
  left: 42%;
  width: 55%;
  height: auto;
  bottom: 12%;
}
.desktop.roulette-left .panno-container #ovale,
.desktop.roulette-left .panno-container #ovalesnap {
  width: 34%;
  left: 53%;
  height: auto;
  bottom: 11%;
}
.desktop.roulette-left .panno-container #ovale #drawOvale,
.desktop.roulette-left .panno-container #ovalesnap #drawOvalesnap {
  transform-origin: -10% center !important;
  transform: perspective(100em) rotateX(43deg) !important;
}
.desktop.roulette-left .panno-container #drawFiche,
.desktop.roulette-left .panno-container #drawPanno,
.desktop.roulette-left .panno-container #drawPda,
.desktop.roulette-left .panno-container #drawSnap {
  transform-origin: 10% !important;
  transform: perspective(100em) rotateX(40deg) !important;
}
.desktop .panno-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 100%;
}
.RL_GREEN .desktop #mlc-video-div {
  position: absolute;
  top: -7.6%;
  left: 0.6%;
}
.RL_GREEN .desktop .panno-container {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/msk/msk-green.png) !important;
}
/*! CSS Used from: Embedded */
.roulette-left #sidepanel {
  right: 0;
}
.roulette-left #sidepanel .sidepanel-menu {
  left: 0;
}
.roulette-left #sidepanel .sidepanel-content {
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4vh 0 0 4vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .roulette-left #sidepanel .sidepanel-content {
    border-radius: 2.3vw 0 0 2.3vw;
  }
}
#sidepanel {
  position: absolute;
  top: 0;
  height: 50%;
  width: 48%;
}
#sidepanel .sidepanel-menu {
  width: 15%;
  height: 100%;
  position: absolute;
  top: 0;
  margin-top: 1vh;
  margin-left: 1vh;
  z-index: 1;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #sidepanel .sidepanel-menu {
    margin-top: 0.6vw;
    margin-left: 0.6vw;
  }
}
#sidepanel .sidepanel-menu .mlc-btn {
  height: 6vh;
  width: 6vh;
  margin: 0.5vh;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  #sidepanel .sidepanel-menu .mlc-btn {
    height: 3.4vw;
    width: 3.4vw;
    margin: 0.3vw;
  }
}
#sidepanel .sidepanel-menu .mlc-btn.btn-chat {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/chat.svg);
}
#sidepanel .sidepanel-menu .mlc-btn.btn-statistic {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/stat.svg);
}
#sidepanel .sidepanel-menu .mlc-btn.btn-winner-bet {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/winner-bet.svg);
  background-size: 50%;
}
#sidepanel .sidepanel-menu .mlc-btn.btn-live-stat {
  background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/live-stat.svg);
  background-size: 55%;
}
#sidepanel .sidepanel-content {
  height: 100%;
  width: 86%;
  position: absolute;
  top: 0;
}
.OutRight {
  -webkit-animation-name: OutRight;
  animation-name: OutRight;
}
/*! CSS Used from: Embedded */
mat-menu {
  display: none;
}
/*! CSS Used from: Embedded */
#fiche1 .color {
  fill: #317323;
}
#fiche2 .color {
  fill: #78a441;
}
#fiche3 .color {
  fill: #e5c900;
}
#fiche4 .color {
  fill: #ff6d2d;
}
#fiche5 .color {
  fill: #c71f21;
}
#fiche6 .color {
  fill: #7720c8;
}
#fiche7 .color {
  fill: #3560b0;
}
/*! CSS Used from: Embedded */
.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
.mat-dialog-content {
  display: block;
  margin: 0 -24px;
  padding: 0 24px;
  max-height: 65vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mat-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  box-sizing: content-box;
  margin-bottom: -24px;
}
/*! CSS Used from: Embedded */
.message-dialog .mat-dialog-container {
  position: absolute;
  width: 60%;
  margin-left: 20%;
  height: auto;
  color: #fff;
  left: 0;
  margin-top: -49vh;
  font-family: Montserrat, sans-serif;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
  padding: 1.5%;
  overflow: hidden;
  vertical-align: middle;
  z-index: 99;
}
.message-dialog .mat-dialog-container .ico-message-dialog {
  width: 2em;
  height: 2em;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  vertical-align: middle;
}
.message-dialog .mat-dialog-container p {
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0 0 3vh;
  margin: 0;
  font-size: 1em;
}
.message-dialog.dialog-exit .mat-dialog-container {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  top: 0;
}
.message-dialog.dialog-exit .mat-dialog-container .mat-dialog-content {
  margin-top: 35vh;
}
.message-dialog.dialog-exit .mat-dialog-container .mat-dialog-content p {
  width: 50%;
  font-size: 1.4em;
  font-weight: 700;
  text-align: left;
  margin-left: 38%;
  padding: 0;
}
.message-dialog.dialog-exit
  .mat-dialog-container
  .mat-dialog-content
  .dealer-picture {
  display: block !important;
  width: 10%;
  height: auto;
  padding-bottom: 10%;
  background: #111;
  border: 5px solid #222;
  position: absolute;
  left: 25%;
  border-radius: 50%;
  top: 36%;
  overflow: hidden;
}
.message-dialog.dialog-exit
  .mat-dialog-container
  .mat-dialog-content
  .dealer-picture
  img {
  width: 100%;
  height: 100%;
  position: absolute;
}
.message-dialog.dialog-exit .mat-dialog-container .mat-dialog-actions {
  margin-right: 0.5em;
  display: inline-block;
}
.message-dialog.dialog-exit
  .mat-dialog-container
  .mat-dialog-actions:first-of-type {
  margin-left: 40%;
}
.message-dialog.dialog-exit .mat-dialog-container .mat-dialog-actions button {
  background-color: transparent;
  font-size: 0.8em;
  padding: 0.7em 2em;
  color: #fff;
  font-weight: 700;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff !important;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 5px;
  outline: none;
}
.message-dialog.dialog-exit
  .mat-dialog-container
  .mat-dialog-actions
  button:active,
.message-dialog.dialog-exit
  .mat-dialog-container
  .mat-dialog-actions
  button:focus,
.message-dialog.dialog-exit
  .mat-dialog-container
  .mat-dialog-actions
  button:hover {
  border: 2px solid #fff !important;
  color: #d7a024;
  background-color: #222;
}
@media (min-height: 56.25vw) and (max-width: 177.8vh) {
  .message-dialog .mat-dialog-container {
    margin-top: -28vw;
  }
  .message-dialog .mat-dialog-container p {
    padding: 0 2vw;
  }
}
@media screen and (orientation: portrait), screen and (max-aspect-ratio: 13/9) {
  .message-dialog .mat-dialog-container {
    margin-top: -45vh;
  }
  .message-dialog .mat-dialog-container p {
    font-size: -0.5em;
  }
}
/*! CSS Used keyframes */
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
@-webkit-keyframes infopanel-fadeOutLeft {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 0;
  }
}
@keyframes infopanel-fadeOutLeft {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes OutRight {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(86%, 0, 0);
  }
}
@keyframes OutRight {
  0% {
    transform: translateZ(0);
  }
  to {
    transform: translate3d(86%, 0, 0);
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
    U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
    U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
    U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
    U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
    U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
