<template>
  <div v-if="showToast" class="toast-container" :style="{ border: `2px solid ${color}`, borderRadius: '10px' }">
    <div class="toast-message">
      <h3>{{ title }}</h3>
      <p v-if="message">{{ message }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showToast: false,
      message: '',
      title: '',
      color: '',
    };
  },
  methods: {
    displayToast(title, message, color = '#ff0000') {
      if (this.showToast) return; 

      this.title = title;
      this.message = message;
      this.color = color;
      this.showToast = true;

      setTimeout(() => {
        this.showToast = false;
      }, 7000);
    },
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  top: 0;
  left: 25%;
  width: 50%;
  height: 72px;
  background: rgba(0, 0, 0, 0.754);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.toast-message {
  color: #fff;
  text-align: center;
}

h3 {
  font-size: 20px;
  margin: 0;
}

p {
  font-size: 16px;
  margin: 0;
}
</style>
