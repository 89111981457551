import Vue from 'vue'
import AppToast from '../components/AlertToast.vue'
import enLocale from '../locales/en.json';
import zhLocale from '../locales/zh.json';
import itLocale from '../locales/it.json';

let ToastInstance = null

const AppToastPlugin = {
  install(vue) {
    const AppToastConstructor = Vue.extend(AppToast)
// 默认语言为英文
let currentLocale = 'en'

// 根据当前语言获取对应的消息
const getMessage = (key) => {
  switch (sessionStorage.getItem("language")) {
    case 'en':
      if(key.includes('min')){
        var tmpKey = key;
        return tmpKey.replace('min', enLocale['min']);
      }else{
        return enLocale[key];
      }
    case 'cn':
      console.log('进来key',key,key.includes('min'))
      if(key.includes('min')){
        var tmpKey = key;
        console.log('tmpKey',tmpKey,tmpKey.replace('min', zhLocale['min']))
        return tmpKey.replace('min', zhLocale['min']);
      }else{
        return zhLocale[key];
      }
    case 'it':
      if(key.includes('min')){
        var tmpKey = key;
        return tmpKey.replace('min', itLocale['min']);
      }else{
        return itLocale[key];
      }
    default:
      console.error(`Locale '${currentLocale}' is not supported.`);
      return '';
  }
}
    vue.prototype.$appToast = (title, message,color) => {
      if (ToastInstance) {
        ToastInstance.displayToast(getMessage(title), message,color)
      } else {
        ToastInstance = new AppToastConstructor({
          el: document.createElement('div'),
        })
        document.body.appendChild(ToastInstance.$el)
        ToastInstance.displayToast(getMessage(title), message,color)
      }
    }

    vue.mixin({
      created: function() {
        this.$appToast = vue.prototype.$appToast
      }
    })
  }
}

export default AppToastPlugin
