<template>
  <div class="app-toast w-3/4 md:w-1/3 flex-col items-between hidden md:flex"
       :class="message == '' ? 'justify-center' : 'justify-between'"
       v-if="showMessage">
    <div class="pr-8 w-full text-right">
      <h3 class="md:text-xl font-bold">{{ title }}</h3>
    </div>

    <div class="pr-4 flex" v-if="message !== ''">
      <div class="w-1/3 md:flex-0">
      </div>
      <div v-html="message" class="w-2/3 md:flex-1 justify-end flex text-sm md:text-base">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppToast",
  data() {
    return {
      showMessage: false,
      message: '',
      title: '',
      toastTimeout: null
    }
  },
  methods: {
    showToast(title, message) {
      clearTimeout(this.toastTimeout);

      this.title = title;
      this.message = message;
      this.showMessage = true;
      this.toastTimeout = setTimeout(() => {
        this.showMessage = false;
      }, 7000); // 7000 matches the length of your animation
    }
  }
}
</script>

<style scoped>
.app-toast {
  background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 15%, rgba(0, 0, 0, 0.5) 100%);
  height: 72px;
  position: absolute;
  top: -72px;
  left: 10%;
  right: 10%;
  color: #fff;
  border-radius: 3vh 3vh 0 0;
  z-index: 50;
  opacity: 0;
  animation: 7s ease 0s normal forwards 1 toast;
}

@keyframes toast {
  0% {
    top: -72px;
    opacity: 0
  }

  25% {
    top: 0px;
    opacity: 1
  }

  75% {
    top: 0px;
    opacity: 1
  }

  100% {
    top: -72px;
    opacity: 0
  }
}
</style>
