<template
>
  
  <div class="RL_GREEN lang-en roulette-left">
      <div
        _ngcontent-gla-c0=""
        class="mlc-container desktop BETCLOSE roulette-left"
        id="mlc-container"
      >
        <div _ngcontent-gla-c0="" class="mlc-view">
            <div
              class="limits-list ng-star-inserted"
              style="background-image: url('assets/bg_limiti.jpg');
              "
            >
              <div class="limits ng-star-inserted">
                <div class="limit-element ng-star-inserted" @click="handleJoin(0.5)" id="0.5">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 1</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values"><span>0.5</span><i>-</i><span>5</span></h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(1)" id="1">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 2</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values"><span>1</span><i>-</i><span>10</span></h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(2)" id="2">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 3</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values"><span>2</span><i>-</i><span>20</span></h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(3)" id="3">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 4</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values"><span>3</span><i>-</i><span>30</span></h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(4)" id="4">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 5</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values"><span>4</span><i>-</i><span>40</span></h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(5)" id="5">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 6</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values"><span>5</span><i>-</i><span>50</span></h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(10)" id="10">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 7</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values">
                    <span>10</span><i>-</i><span>100</span>
                  </h3>
                </div>
                <div class="limit-element ng-star-inserted" @click="handleJoin(20)" id="20">
                  <div class="ico-roulette"></div>
                  <div class="currency">EUR</div>
                  <h3 class="table">Table <span> 8</span></h3>
                  <h4 class="subtitle">Limit :</h4>
                  <h3 class="values">
                    <span>20</span><i>-</i><span>200</span>
                  </h3>
                </div>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>
<script>
export default {
    name:'SelectTable',
    props:{
      handleJoin: {
      type: Function,
      default: () => {}
    }
        
    },
}
</script>
<style scoped>
    /*! CSS Used from: https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/styles.css */
    .animated {
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    .fadeOutDown {
      -webkit-animation-name: fadeOutDown;
      animation-name: fadeOutDown;
    }
    #middleView-mlc-video-div,
    #middleView-mlc-video-div #audio-mlc-video-div {
      display: none !important;
    }
    .disabled {
      opacity: 0.3 !important;
      pointer-events: none !important;
    }
    .disabled:hover {
      cursor: default !important;
      background-color: transparent !important;
      transform: scale(1) !important;
      -o-box-shadow: unset !important;
      box-shadow: unset !important;
    }
    .pevents-off {
      pointer-events: none;
    }
    .pevents-on {
      pointer-events: visible;
    }
    .hide {
      display: none !important;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-moz-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background-color: #333;
    }
    ::-moz-scrollbar-track {
      background-color: #333;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
    ::-moz-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
    }
    ::-webkit-scrollbar-button {
      background-color: rgba(0, 0, 0, 0.8);
    }
    ::-moz-scrollbar-button {
      background-color: rgba(0, 0, 0, 0.8);
    }
    ::-webkit-scrollbar-corner {
      background-color: #000;
    }
    ::-moz-scrollbar-corner {
      background-color: #000;
    }
    * {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    html {
      font-family: Montserrat, sans-serif;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      position: relative;
      height: 100%;
      overflow: hidden;
    }
    body {
      background-color: #000;
      margin: 0;
      color: #fff;
      overflow: hidden;
      height: 101%;
    }
    h1,
    h3,
    h4 {
      font-family: inherit;
      font-weight: 500;
      line-height: 1.1;
      color: inherit;
    }
    #overlay-sfumatura {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .mlc-btn {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.6);
      background-repeat: no-repeat;
      background-position-x: 50%;
      background-position-y: 50%;
      background-size: 100%;
      z-index: 1;
      display: inline-block;
      border-radius: 50%;
      transition: 0.2s;
      box-shadow: -2px -4px 12px rgba(0, 0, 0, 0.5);
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      border: 0.3vh solid #fff;
    }
    .mlc-btn:hover {
      background-color: #666;
      -o-box-shadow: 0 0 1.5em 0 #fff;
      box-shadow: 0 0 1.5em 0 #fff;
    }
    .mlc-btn:active,
    .mlc-btn:focus {
      outline: 0 !important;
    }
    .mlc-btn:active {
      transform: scale(0.9);
      -o-box-shadow: 0 0 1.5em 0 #fff;
      box-shadow: 0 0 1.5em 0 #fff;
      opacity: 0.8;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .mlc-btn {
        border-width: 0.17vw;
      }
    }
    button {
      padding: 0;
      align-items: flex-start;
      text-align: center;
      cursor: default;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 1.5em;
      transition: 0.5s;
      color: #f5f5f5;
      font-weight: 100;
      pointer-events: visible;
      border: 0 solid transparent;
    }
    button:hover {
      color: #c90;
      cursor: pointer;
      outline: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
    }
    button:active,
    button:focus {
      outline: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
    }
    /*! CSS Used from: Embedded */
    
    .mlc-container[_ngcontent-gla-c0] {
      position: absolute;
      width: 100vw;
      height: 56.25vw;
      background: #000;
      max-height: 100vh;
      max-width: 177.78vh;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      overflow: hidden;

    }

    @media screen and (orientation: portrait) {
      .mlc-container[_ngcontent-gla-c0] {
        position: absolute;
        height: 100vh;
        width: 100vw;
        background: #000;
        max-height: 100vh;
        max-width: 177.78vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        overflow: hidden;
      }
    }

    .mlc-footer[_ngcontent-gla-c0],
    .mlc-header[_ngcontent-gla-c0] {
      width: 100%;
      position: absolute;
      pointer-events: none;
    }
    .mlc-header[_ngcontent-gla-c0] {
      height: 15%;
      top: 0;
    }
    .mlc-footer[_ngcontent-gla-c0] {
      height: 10%;
      bottom: 0;
    }
    .mlc-main[_ngcontent-gla-c0] {
      position: absolute;
      top: 15%;
      width: 100%;
      height: 85%;
      text-align: center;
    }
    .mlc-main[_ngcontent-gla-c0] .mlc-left[_ngcontent-gla-c0],
    .mlc-main[_ngcontent-gla-c0] .mlc-right[_ngcontent-gla-c0] {
      width: 16%;
      height: 85%;
      position: absolute;
      top: 0;
      margin-top: 2px;
      z-index: 1;
    }
    .mlc-main[_ngcontent-gla-c0] .mlc-left[_ngcontent-gla-c0] {
      left: 0;
    }
    .mlc-main[_ngcontent-gla-c0] .mlc-right[_ngcontent-gla-c0] {
      right: 0;
    }
    .mlc-box-container[_ngcontent-gla-c0],
    .mlc-view[_ngcontent-gla-c0] {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      margin: auto;
      pointer-events: none;
    }
    .mlc-view[_ngcontent-gla-c0] {
      z-index: 99;
    }
    .mlc-box-container[_ngcontent-gla-c0] {
      z-index: 98;
    }
    #mlc-video-div[_ngcontent-gla-c0] {
      display: block;
      overflow: hidden;
      background-color: #000;
      opacity: 1;
      width: 100%;
      height: 100%;
      margin: auto;
      transition: all 0.2s linear;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    /*! CSS Used from: Embedded */
    .infotavolo {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 30%;
      margin-top: 3px;
      text-shadow: #000 0 0 1em;
      pointer-events: none;
    }
    .infotavolo h1 {
      color: #c00;
      margin: 0 0 0 2px;
      font-size: 3vh;
      font-weight: 500;
      position: relative;
    }
    .infotavolo h1 .game {
      color: #c00;
      text-transform: uppercase;
      position: absolute;
      top: 6%;
      text-shadow: #000 0 0 0.4em;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .infotavolo h1 {
        font-size: 1.6vw;
      }
    }
    .desktop .infotavolo h1 .game {
      font-size: 1.4vh;
    }
    .infotavolo h1 > i {
      color: #fff;
      font-size: 0.3em;
      font-style: normal;
    }
    .infotavolo h1 > span {
      color: #fff;
      font-size: 0.4em;
      text-transform: uppercase;
    }
    .infotavolo p {
      position: relative;
      font-size: 1vh;
      margin: 0 0 0 2px;
      color: #ccc;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .desktop .infotavolo h1 .game {
        font-size: 0.8vw;
      }
      .infotavolo p {
        font-size: 0.6vw;
      }
    }
    /*! CSS Used from: Embedded */
    .infogioco {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30%;
      text-shadow: #000 0 0 1em;
    }
    .infogioco .dati-gioco {
      font-weight: 300;
      text-align: right;
      color: #ccc;
      float: right;
      margin-right: 1vh;
    }
    .infogioco .dati-gioco p {
      font-size: 1.5vh;
    }
    .infogioco .dati-gioco p span {
      font-weight: 500;
      font-size: 1.5vh;
      line-height: 1.2;
      width: 100%;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .infogioco .dati-gioco {
        margin-right: 0.6vw;
      }
      .infogioco .dati-gioco p {
        font-size: 0.85vw;
      }
      .infogioco .dati-gioco p span {
        font-size: 0.8vw;
      }
      .infogioco .dati-gioco p span.time {
        margin-right: 0.6vw;
      }
    }
    .infogioco .dati-gioco p span.time {
      display: inline-block;
      width: 3.2vw;
      text-align: left;
    }
    .infogioco .dati-gioco #eventId {
      font-size: 1.5vh;
    }
    .infogioco .mlc-btn {
      height: 4vh;
      width: 4vh;
      margin-top: 3%;
      float: right;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .infogioco .dati-gioco #eventId {
        font-size: 0.85vw;
      }
      .infogioco .mlc-btn {
        height: 2vw;
        width: 2vw;
      }
    }
    .infogioco .mlc-btn.ico-exit {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/exit.svg);
    }
    .infogioco .tooltip-datigioco {
      border: 2px solid #00aeef;
      position: absolute;
      top: 50%;
      right: 2%;
      padding: 1vh 2vw;
      font-size: 1.5vh;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
      box-shadow: 0 0 10px #000;
      display: none;
      width: auto;
    }
    .infogioco .tooltip-datigioco .triangolo {
      position: absolute;
      top: -1.1vh;
      left: 60%;
      width: 0;
      height: 0;
      line-height: 0;
      border-bottom: 1vh solid #00aeef;
      border-left: 0.8vh solid transparent;
      border-right: 0.8vh solid transparent;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .infogioco .tooltip-datigioco {
        font-size: 0.85vw;
        padding: 1vw 1.5vh;
      }
      .infogioco .tooltip-datigioco .triangolo {
        top: -0.8vw;
        border-bottom: 0.8vw solid #00aeef;
        border-left: 0.6vw solid transparent;
        border-right: 0.6vw solid transparent;
      }
    }
    /*! CSS Used from: Embedded */
    /* #overlay-wheel {
      width: 15.95vh;
      height: 15.95vh;
      position: absolute;
      border-radius: 50%;
      margin-left: 14.4%;
      margin-top: -0.8%;
      z-index: 11;
      background: linear-gradient(to left, #000 -2%, rgba(0, 0, 0, 0) 35%),
        linear-gradient(to right, #000 -2%, rgba(0, 0, 0, 0) 35%),
        linear-gradient(to top, #000 -20%, rgba(0, 0, 0, 0) 40%),
        linear-gradient(to bottom, #000 -20%, #00000000 40%);
    }
    #window-wheel {
      width: 15.4vh;
      height: 15.4vh;
      box-shadow: 0 0 20px #000;
      position: absolute;
      overflow: hidden;
      border-radius: 50%;
      margin-left: 15%;
      margin-top: 0;
      z-index: 10;
    }
    #window-wheel h3 {
      height: 100%;
      width: 100%;
      font-size: 2vh;
      font-weight: 800;
      color: #fff;
      text-shadow: #fff 0 0 1em;
      position: absolute;
      top: -13%;
      padding-top: 37%;
      background: rgba(0, 0, 0, 0.8);
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      #overlay-wheel {
        width: 9vw;
        height: 9vw;
      }
      #window-wheel {
        width: 8.7vw;
        height: 8.7vw;
      }
      #window-wheel h3 {
        font-size: 1.14vw;
      }
    }
    #window-wheel #wheel {
      width: 580%;
      height: 580%;
      position: absolute;
      top: -3%;
      left: -240%;
      z-index: 9;
    }
    #window-wheel #wheel .black {
      fill: #000;
      stroke-width: 0;
    }
    #window-wheel #wheel .red {
      fill: #c00;
      stroke-width: 0;
    }
    #window-wheel #wheel .green {
      fill: #390;
      stroke-width: 0;
    }
    #window-wheel #wheel svg text {
      font-family: Montserrat, sans-serif !important;
    } */
    /*! CSS Used from: Embedded */
    .settings {
      position: absolute;
      bottom: 0;
      margin-left: 2%;
      margin-bottom: -5%;
      pointer-events: visible;
    }
    .settings .button-menu {
      width: 6vh;
      height: 6vh;
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/menu.svg);
      background-size: 75%;
      position: absolute;
      bottom: 25%;
      left: 2%;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .settings .button-menu {
        height: 3.4vw;
        width: 3.4vw;
      }
    }
    /*! CSS Used from: Embedded */
    #game-state {
      width: 50%;
      position: absolute;
      top: 6%;
      margin-left: 18%;
      z-index: 99;
    }
    #game-state h3 {
      font-size: 2vh;
      font-weight: 800;
      text-shadow: #fff 0 0 1em;
      margin: 9% 0 0 -4%;
    }
    #counter {
      width: 17.7vh;
      height: 17.7vh;
      position: absolute;
      margin-left: 11.3%;
      margin-top: -3.9%;
      z-index: 12;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      #game-state h3 {
        font-size: 1.14vw;
      }
      #counter {
        width: 10vw;
        height: 10vw;
      }
    }
    /*! CSS Used from: Embedded */
    #info-panel {
      background: rgba(0, 0, 0, 0.5);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0,
        rgba(0, 0, 0, 0.5) 15%,
        rgba(0, 0, 0, 0.5) 100%
      );
      width: 26vh;
      height: 7.87vh;
      position: absolute;
      top: 3%;
      left: 53%;
      color: #fff;
      border-radius: 0 3vh 7vh 0;
      padding: 2vh 1vh;
      z-index: 0;
    }
    #info-panel h3,
    #info-panel p {
      width: 83%;
      font-weight: 800;
      float: right;
    }
    #info-panel h3 {
      font-size: 2.5vh;
      margin-top: 0;
      margin-bottom: 1vh;
    }
    #info-panel p {
      font-size: 1.7vh;
      margin: 0.5%;
      font-weight: 400;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      #info-panel {
        width: 14.82vw;
        height: 4.5vw;
        font-size: 1vw;
        border-radius: 0 1.71vw 3.99vw 0;
        padding: 1.14vw 0.57vw;
      }
      #info-panel h3 {
        font-size: 1.4vw;
        margin-bottom: 0.6vw;
      }
      #info-panel p {
        font-size: 1vw;
      }
    }
    .infopanel-fadeOutLeft {
      -webkit-animation-name: infopanel-fadeOutLeft;
      animation-name: infopanel-fadeOutLeft;
    }
    /*! CSS Used from: Embedded */
    .ultimi-numeri {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      padding-top: 60%;
    }
    .desktop .ultimi-numeri {
      height: 65%;
      top: 23%;
      padding-top: 0;
      overflow-y: scroll;
      pointer-events: visible;
      scrollbar-width: none;
      scrollbar-width: none !important;
      -ms-overflow-style: none;
    }
    .desktop .ultimi-numeri::-webkit-scrollbar {
      width: 0 !important;
    }
    .desktop .ultimi-numeri::-moz-scrollbar {
      width: 0 !important;
    }
    .ultimi-numeri .num {
      display: inline-block;
      width: 36%;
      text-align: center;
      margin: 0 18%;
    }
    .ultimi-numeri .num > span {
      background: #fff;
      border: 0.3vh solid #333;
      color: #fff;
      width: 4vh;
      height: 4vh;
      display: inline-block;
      border-radius: 50%;
      font-family: Montserrat, sans-serif;
      font-size: 2.3vh;
      line-height: 4vh;
      font-weight: 600;
      text-align: center;
      box-shadow: #666 0 0 1em;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .ultimi-numeri .num > span {
        width: 2.3vw;
        height: 2.3vw;
        font-size: 1.4vw;
        line-height: 2.3vw;
        border: 0.17vw solid #333;
      }
    }
    .ultimi-numeri .num > span.red {
      background: #c00;
      float: left;
    }
    .ultimi-numeri .num > span.black {
      background: #000;
      float: right;
    }
    .mlc-left .ultimi-numeri {
      position: absolute;
    }
    /*! CSS Used from: Embedded */
    .saldo {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20%;
      height: 100%;
      pointer-events: visible;
    }
    .saldo .txt-saldo {
      text-align: left;
      width: 100%;
      position: absolute;
      left: 1vh;
      bottom: 10%;
    }
    .saldo h3 {
      color: #969696;
      font-size: 1.6vh;
      margin: 0;
    }
    .saldo h3 > .valuta {
      color: #d8a100;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .saldo .txt-saldo {
        left: 0.8vw;
      }
      .saldo h3 {
        font-size: 0.8vw;
      }
    }
    .saldo p {
      margin: 0 0 3px;
      padding: 0;
      font-size: 2.1vh;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .saldo p {
        font-size: 1.2vw;
      }
    }
    /*! CSS Used from: Embedded */
    .rebet {
      position: absolute;
    }
    .rebet .mlc-btn {
      pointer-events: visible;
    }
    .rebet .mlc-btn.btn-rebet {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/rebet.svg);
      background-size: 65%;
      background-position: 55%;
    }
    .desktop .rebet {
      left: unset;
      right: 45%;
      top: 8%;
    }
    .desktop .rebet .mlc-btn {
      width: 7vh;
      height: 7vh;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .desktop .rebet .mlc-btn {
        width: 4vw;
        height: 4vw;
      }
    }
    /*! CSS Used from: Embedded */
    .gettoniera {
      width: auto;
      height: 70%;
      text-align: center;
      position: absolute;
      top: 10%;
      right: 16%;
      pointer-events: visible;
    }
    /*! CSS Used from: Embedded */
    .btn-undo-container {
      position: absolute;
      border-radius: 50%;
      pointer-events: visible;
    }
    .btn-undo-container .mlc-btn {
      position: absolute;
    }
    .btn-undo-container .mlc-btn.btn-undo-base {
      z-index: 21;
    }
    .btn-undo-container .mlc-btn.btn-undo-sub {
      z-index: 19;
    }
    .btn-undo-container .ico-undo-base {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/del.svg);
    }
    .btn-undo-container .ico-undo-all {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/clear_all.svg);
      background-size: 55%;
    }
    .desktop .btn-undo-container {
      left: unset;
      right: 55%;
      top: 8%;
    }
    .desktop .btn-undo-container .mlc-btn.btn-undo-base {
      width: 7vh;
      height: 7vh;
    }
    .desktop .btn-undo-container .mlc-btn.btn-undo-sub {
      width: 4vh;
      height: 4vh;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .desktop .btn-undo-container .mlc-btn.btn-undo-base {
        width: 4vw;
        height: 4vw;
      }
      .desktop .btn-undo-container .mlc-btn.btn-undo-sub {
        width: 2.3vw;
        height: 2.3vw;
      }
    }
    /*! CSS Used from: Embedded */
    .puntata {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 20%;
      height: 100%;
    }
    .puntata .txt-puntata {
      text-align: right;
      width: 100%;
      position: absolute;
      right: 1vh;
      bottom: 10%;
    }
    .puntata .txt-puntata h3 {
      color: #969696;
      font-size: 1.6vh;
      margin: 0;
      text-transform: uppercase;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .puntata .txt-puntata {
        right: 0.8vw;
      }
      .puntata .txt-puntata h3 {
        font-size: 0.8vw;
      }
    }
    .puntata .txt-puntata h3 > .valuta {
      color: #d8a100;
    }
    .puntata .txt-puntata p {
      margin: 0 0 3px;
      padding: 0;
      font-size: 2.1vh;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .puntata .txt-puntata p {
        font-size: 1.2vw;
      }
    }
    /*! CSS Used from: Embedded */
    .roulette-left #limiti {
      left: 9%;
    }
    #limiti {
      width: 10%;
      height: auto;
      position: absolute;
      bottom: 7%;
      z-index: 1;
    }
    #limiti .txt-limiti tspan {
      text-transform: uppercase;
      text-shadow: #000 0 0 1px;
      transition: 0.2s;
    }
    #limiti:hover {
      cursor: pointer;
    }
    /*! CSS Used from: Embedded */
    .panno-container #fiche,
    .panno-container #ovale,
    .panno-container #ovalesnap,
    .panno-container #panno,
    .panno-container #pda,
    .panno-container #snap {
      position: absolute;
      height: inherit;
      width: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .panno-container #fiche,
    .panno-container #ovale,
    .panno-container #panno,
    .panno-container #pda,
    .panno-container #snap {
      pointer-events: none;
    }
    .panno-container #ovale,
    .panno-container #panno {
      opacity: 0.85;
    }
    .panno-container #ovale text,
    .panno-container #panno text {
      text-shadow: #000 0 0 40px;
    }
    .panno-container .snap-point {
      opacity: 0;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      pointer-events: visible;
    }
    .panno-container .snap-point:active,
    .panno-container .snap-point:focus {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    }
    .panno-container .snap-point:hover {
      opacity: 0.6;
      fill-opacity: 0;
    }
    /*! CSS Used from: Embedded */
    .panno-container .red {
      fill: #c00;
      stroke: #fff;
      stroke-width: 2;
    }
    .panno-container .black {
      fill: #000;
      stroke: #fff;
      stroke-width: 2;
    }
    .panno-container .green {
      fill: #390;
      stroke: #fff;
      stroke-width: 2;
    }
    .panno-container .transparent {
      fill: #333;
      stroke: #fff;
      stroke-width: 2;
    }
    /*! CSS Used from: Embedded */
    .panno-container .red {
      fill: #c00;
      stroke: #fff;
      stroke-width: 2;
    }
    .panno-container .black {
      fill: #000;
      stroke: #fff;
      stroke-width: 2;
    }
    .panno-container .green {
      fill: #390;
      stroke: #fff;
      stroke-width: 2;
    }
    .panno-container .transparent {
      fill: #333;
      stroke: #fff;
      stroke-width: 2;
    }
    /*! CSS Used from: Embedded */
    #tooltip {
      position: absolute;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      font-size: 2vh;
      border-radius: 1vh;
      z-index: 99999999;
      box-sizing: border-box;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
      pointer-events: none;
      display: none;
    }
    #tooltip i {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;
    }
    #tooltip i::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    }
    #tooltip .tooltip-title {
      border-radius: 1vh 1vh 0 0;
      padding: 0.8vh;
    }
    #tooltip .tooltip-title h3 {
      margin: 0;
    }
    #tooltip .tooltip-body {
      padding: 1vh;
    }
    .BETCLOSE #tooltip {
      display: none !important;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      #tooltip {
        font-size: 1.14vw;
        border-radius: 0.57vw;
      }
      #tooltip .tooltip-title {
        border-radius: 0.57vw 0.57vw 0 0;
        padding: 0.46vw;
      }
      #tooltip .tooltip-body {
        padding: 0.57vw;
      }
    }
    /*! CSS Used from: Embedded */
    .desktop.roulette-left .panno-container #fiche,
    .desktop.roulette-left .panno-container #panno,
    .desktop.roulette-left .panno-container #pda,
    .desktop.roulette-left .panno-container #snap {
      left: 40%;
      width: 58%;
      height: auto;
      bottom: 12%;
    }
    .RL_GREEN .desktop.roulette-left .panno-container #fiche,
    .RL_GREEN .desktop.roulette-left .panno-container #panno,
    .RL_GREEN .desktop.roulette-left .panno-container #pda,
    .RL_GREEN .desktop.roulette-left .panno-container #snap {
      left: 42%;
      width: 55%;
      height: auto;
      bottom: 12%;
    }
    .desktop.roulette-left .panno-container #ovale,
    .desktop.roulette-left .panno-container #ovalesnap {
      width: 34%;
      left: 53%;
      height: auto;
      bottom: 11%;
    }
    .desktop.roulette-left .panno-container #ovale #drawOvale,
    .desktop.roulette-left .panno-container #ovalesnap #drawOvalesnap {
      transform-origin: -10% center !important;
      transform: perspective(100em) rotateX(43deg) !important;
    }
    .desktop .panno-container {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center bottom;
      width: 100%;
      height: 100%;
    }
    .RL_GREEN .desktop #mlc-video-div {
      position: absolute;
      top: -7.6%;
      left: 0.6%;
    }
    .RL_GREEN .desktop .panno-container {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/msk/msk-green.png) !important;
    }
    /*! CSS Used from: Embedded */
    .roulette-left #sidepanel {
      right: 0;
    }
    .roulette-left #sidepanel .sidepanel-menu {
      left: 0;
    }
    .roulette-left #sidepanel .sidepanel-content {
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4vh 0 0 4vh;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .roulette-left #sidepanel .sidepanel-content {
        border-radius: 2.3vw 0 0 2.3vw;
      }
    }
    #sidepanel {
      position: absolute;
      top: 0;
      height: 50%;
      width: 48%;
    }
    #sidepanel .sidepanel-menu {
      width: 15%;
      height: 100%;
      position: absolute;
      top: 0;
      margin-top: 1vh;
      margin-left: 1vh;
      z-index: 1;
    }
    #sidepanel .sidepanel-menu .mlc-btn {
      height: 6vh;
      width: 6vh;
      margin: 0.5vh;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      #sidepanel .sidepanel-menu {
        margin-top: 0.6vw;
        margin-left: 0.6vw;
      }
      #sidepanel .sidepanel-menu .mlc-btn {
        height: 3.4vw;
        width: 3.4vw;
        margin: 0.3vw;
      }
    }
    #sidepanel .sidepanel-menu .mlc-btn.btn-chat {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/chat.svg);
    }
    #sidepanel .sidepanel-menu .mlc-btn.btn-statistic {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/stat.svg);
    }
    #sidepanel .sidepanel-menu .mlc-btn.btn-winner-bet {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/winner-bet.svg);
      background-size: 50%;
    }
    #sidepanel .sidepanel-menu .mlc-btn.btn-live-stat {
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/ico/live-stat.svg);
      background-size: 55%;
    }
    #sidepanel .sidepanel-content {
      height: 100%;
      width: 86%;
      position: absolute;
      top: 0;
    }
    .OutRight {
      -webkit-animation-name: OutRight;
      animation-name: OutRight;
    }
    /*! CSS Used from: Embedded */
    .limits-list {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: bottom right/cover no-repeat #000;
      display: block;
      pointer-events: visible;
    }
    .limits-list .limits {
      height: 70%;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      padding-right: 10%;
      padding-left: 2%;
      margin-top: 5%;
    }

    @media screen and (orientation: portrait) {
      .limits-list .limits {
        height: 70%;
        display: grid;
        grid-template-columns: 50% 50%;
        padding-right: 10%;
        padding-left: 6% !important;
        margin-top: 20% !important;

      }

      .limits-list .limits .limit-element {
        width: 28.6vw !important;
        height: 28.6vw !important;
        border: 0.3vw solid #c90;
      }
      .limits-list .limits .limit-element .ico-roulette {
        width: 15.4vw !important;
        height: 8.8vw !important;
      }

      .limits-list .limits .limit-element .currency {
          width: 6.8vw !important;
          height: 6.8vw !important;
          font-size: 2.4vw !important;
      }

      .limits-list .limits .limit-element .table{
          font-size: 2.74vw !important;
          margin-top: 6.8vw !important;
          margin-bottom: 0.3vw !important;
      }

      .limits-list .limits .limit-element .subtitle {
          font-size: 2.74vw !important;
      }

      .limits-list .limits .limit-element .values {
          font-size: 4vw !important;
          margin: 2vw 0 !important;
      }
    }
    
    .limits-list .limits .limit-element {
      width: 26vh;
      height: 26vh;
      margin: 5px;
      border: 0.6vh solid #c90;
      border-radius: 50%;
      transition: 0.6s;
      align-self: center;
      justify-self: left;
      text-transform: uppercase;
      position: relative;
      cursor: pointer;
    }
    .limits-list .limits .limit-element:focus,
    .limits-list .limits .limit-element:hover {
      background: rgba(204, 153, 0, 0.1);
      -o-box-shadow: 0 0 1.5em 0 #c90;
      box-shadow: 0 0 1.5em 0 #c90;
    }
    .limits-list .limits .limit-element:active {
      transform: scale(0.9);
      -o-box-shadow: 0 0 1.5em 0 #c90;
      box-shadow: 0 0 1.5em 0 #c90;
      opacity: 0.8;
    }
    .limits-list .limits .limit-element .ico-roulette {
      width: 13vh;
      height: 8vh;
      position: absolute;
      top: 10%;
      right: -30%;
      display: block;
      background-image: url(https://mlrl01.livewebsrv.com/roulette-module/html5/roulette_new/assets/img/general/ico_roulette.png);
      background-repeat: no-repeat;
      background-size: contain;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .limits-list .limits .limit-element {
        width: 14.3vw;
        height: 14.3vw;
        border: 0.3vw solid #c90;
      }
      .limits-list .limits .limit-element .ico-roulette {
        width: 7.7vw;
        height: 4.4vw;
      }
    }
    .limits-list .limits .limit-element .currency {
      width: 5vh;
      height: 5vh;
      background: #333;
      color: #fff;
      font-size: 1.6vh;
      font-weight: 100;
      text-align: center;
      border-radius: 50%;
      position: absolute;
      line-height: 3.1;
      right: -13%;
      top: 34%;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .limits-list .limits .limit-element .currency {
        width: 3.4vw;
        height: 3.4vw;
        font-size: 1.2vw;
      }
    }
    .limits-list .limits .limit-element .table {
      font-weight: 100;
      margin-bottom: 0.3vh;
      margin-top: 4vh;
      width: 65%;
      text-align: right;
      font-size: 2.5vh;
    }
    .limits-list .limits .limit-element .table span {
      font-size: 4.2vh;
      font-weight: 300;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .limits-list .limits .limit-element .table {
        font-size: 1.37vw;
        margin-top: 2.4vw;
        margin-bottom: 0.15vw;
      }
      .limits-list .limits .limit-element .table span {
        font-size: 2.3vw;
      }
    }
    .limits-list .limits .limit-element .subtitle {
      font-weight: 100;
      font-size: 2.5vh;
      width: 65%;
      text-align: right;
      margin: 0 0 0.6vh;
      color: rgba(255, 255, 255, 0.6);
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .limits-list .limits .limit-element .subtitle {
        font-size: 1.37vw;
      }
    }
    .limits-list .limits .limit-element .values {
      font-size: 4.4vh;
      margin: 1.8vh 0;
      text-align: center;
    }
    @media (min-height: 56.25vw) and (max-width: 177.8vh) {
      .limits-list .limits .limit-element .values {
        font-size: 2vw;
        margin: 1vw 0;
      }
    }
    .limits-list .limits .limit-element .values i {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 100;
    }
    /*! CSS Used keyframes */
    @-webkit-keyframes fadeOutDown {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }
    @keyframes fadeOutDown {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
      }
    }
    @-webkit-keyframes infopanel-fadeOutLeft {
      0% {
        opacity: 1;
      }
      30% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes infopanel-fadeOutLeft {
      0% {
        opacity: 1;
      }
      30% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
      }
      100% {
        opacity: 0;
      }
    }
    @-webkit-keyframes OutRight {
      from {
        transform: translate3d(0, 0, 0);
      }
      to {
        transform: translate3d(86%, 0, 0);
      }
    }
    @keyframes OutRight {
      from {
        transform: translate3d(0, 0, 0);
      }
      to {
        transform: translate3d(86%, 0, 0);
      }
    }
    /*! CSS Used fontfaces */
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 200;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
        format("woff2");
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 200;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
        format("woff2");
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 200;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
        format("woff2");
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 200;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
        format("woff2");
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 200;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
        format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
        U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
        format("woff2");
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
        format("woff2");
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
        format("woff2");
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
        format("woff2");
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 300;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
        format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
        U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
        format("woff2");
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
        format("woff2");
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
        format("woff2");
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
        format("woff2");
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
        format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
        U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
        format("woff2");
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
        format("woff2");
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
        format("woff2");
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
        format("woff2");
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
        format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
        U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2)
        format("woff2");
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2)
        format("woff2");
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2)
        format("woff2");
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2)
        format("woff2");
      unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    @font-face {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2)
        format("woff2");
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
        U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
        U+2212, U+2215, U+FEFF, U+FFFD;
    }
  </style>
